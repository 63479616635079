import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, CustomRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { CheckBox } from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';

import globalIcons from '../../../../../config/globalIcons.js';

import HxPopupSaveChanges from '../../../../custom-components/hx-popup-save-changes/HxPopupSaveChanges'

import ServiceProducts from '../../../../../api/services/ServiceProducts';
import ServiceRidersLocations from '../../../../../api/services/ServiceRidersLocations';

import './PopupProducts.scss'

// props
//
// callables
//      preload
//
// callbacks
//      onInitialized
//      onDBError
//      onCreate
//      onUpdate

class PopupProducts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceLocations: []
        }

        this.productId = -1;

        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.textBoxProductNameInstance = null;
        this.selectBoxShopsInstance = null
        this.textBoxArticleNoInstance = null;
        this.textBoxPackagingInstance = null;

        this.productRecord = {};

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.dataHasChanged = false;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    onCreate = (success) => {
        if (this.props.onCreate) {
            this.props.onCreate(success);  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    } 
    
    // CALLABLES
    preload = async (id) => {
        await this.loadDataSourceLocations();
        this.userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        this.productId = id;
        await this.fillPopup(id);
    }

    // EVENTS


    // DATA
    loadDataSourceProduct = async (id) => {
        var data = await ServiceProducts.getProduct(id);
        if (!data.hasOwnProperty('error')) {
            if (data !== null) {
                this.productRecord = data;
            } else {
                this.onDBError('Product not found!');  // callback
            }
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    loadDataSourceLocations = async () => {
        var data = await ServiceRidersLocations.getRidersLocationsDropDown();
        if (!data.hasOwnProperty('error')) {
            if (data !== null) {
                this.setState({ dataSourceLocations: data});
            } else {
                this.onDBError('Locations not found!');  // callback
            }
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        if (this.productId === -1) {
            // new product
            this.checkBoxKeepOpenInstance.option('visible', true)
            this.setPopupTitle();

        } else {
            // update product
            this.checkBoxKeepOpenInstance.option('visible', false)
            this.setPopupTitle();
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '80px');
    }

    popup_OnShown = async (e) => {
        this.textBoxProductNameInstance.focus();
    }

    popup_OnHiding = (e) => {
        if (this.dataHasChanged && this.userSettingsGeneral.popupWarnIfDataHasChanged === true) {
            e.cancel = true;
            this.popupSaveChangesInstance.show();
        }
    }

    fillPopup = async (productId) => {
        await this.loadDataSourceProduct(productId);

        this.textBoxProductNameInstance.option('value', this.productRecord.productName);
        this.selectBoxShopsInstance.option('value', this.productRecord.locationId !== -1 ? this.productRecord.locationId : null);
        this.textBoxPackagingInstance.option('value', this.productRecord.packaging);
        this.textBoxArticleNoInstance.option('value', this.productRecord.articleNo);

        this.dataHasChanged = false;

        await this.validationGroupInstance.validate();
    }

    resetPopup = async () => {
        this.productId = -1;

        await this.fillPopup(this.productId);

        this.setPopupTitle();

        this.checkBoxKeepOpenInstance.option('visible', true)

        await this.validationGroupInstance.validate();

        this.textBoxProductNameInstance.focus();
    }

    setPopupTitle = () => {
        if (this.productId === -1) {
            this.popupInstance.option('title', 'Create product' + (this.dataHasChanged === true ? '*' : ''));
        } else {
            this.popupInstance.option('title', 'Update ' + this.productRecord.productName + (this.dataHasChanged === true ? '*' : ''));
        }
    }

    // POPUP SAVE CHANGES
    popupSaveChanges_onInitialized = (instance) => {
        this.popupSaveChangesInstance = instance;
    }

    popupSaveChanges_onClick = async (value) => {
        this.popupSaveChangesInstance.hide();

        switch (value.toUpperCase()) {
            case "YES":
                await this.updateProduct();
                break;
            case "NO":
                this.dataHasChanged = false;
                this.popupInstance.hide();
                break;
            case "CANCEL":
                break;
            default:
        }
    }

    // ALL INPUTS VALUE CHANGED
    input_ValueChanged = (e) => {
        this.dataHasChanged = true;
        this.setPopupTitle();
    }

    // ALL TEXTBOXES FOCUS IN AND OUT
    textBox_OnFocusIn = (e) => {
        if (this.userSettingsGeneral.inputFocusInSelectAll === true) {
            // select all text
            e.element.querySelector('.dx-texteditor-input').select();
        }
    }

    textBox_OnFocusOut = (e) => {
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
    }

    // TEXTBOX TAG
    textBoxProductName_OnInitialized = (e) => {
        this.textBoxProductNameInstance = e.component;
    }

    // SELECTBOX SHOPS
    selectBoxShops_OnInitialized = (e) => {
        this.selectBoxShopsInstance = e.component;
    }

    // TEXTBOX PACKAGING
    textBoxPackaging_OnInitialized = (e) => {
        this.textBoxPackagingInstance = e.component;
    }

    // TEXTBOX ARTICLENO
    textBoxArticleNo_OnInitialized = (e) => {
        this.textBoxArticleNoInstance = e.component;
    }

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '120px' : '80px'));
    }

    // BUTTON UPDATE
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateProduct();
    }

    updateProduct = async () => {
        // run async validation rules again because of bug in dxValidationGroup
        var resProductName = await this.validateProductName();

        var res = await this.validationGroupInstance.validate();

        if (res.isValid === true && resProductName === true) {
            this.productRecord.productName = this.textBoxProductNameInstance.option('value');
            this.productRecord.locationId = this.selectBoxShopsInstance.option('value');
            this.productRecord.packaging = this.textBoxPackagingInstance.option('value');
            this.productRecord.articleNo = this.textBoxArticleNoInstance.option('value');

            if (this.productId === -1) {
                // new product
                var resultProduct = await ServiceProducts.createProduct(this.productRecord);
                if (!resultProduct.hasOwnProperty("error")) {
                    this.onCreate(resultProduct); // callback
                    if (resultProduct !== -1) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.dataHasChanged = false;
                            this.popupInstance.hide();
                        } else {
                            this.resetPopup();
                            this.setPopupTitle();
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            } else {
                // update product
                var resultProductUpdate = await ServiceProducts.updateProduct(this.productRecord);
                if (!resultProductUpdate.hasOwnProperty("error")) {
                    this.onUpdate(resultProductUpdate); // callback
                    if (resultProductUpdate === true) {
                        this.dataHasChanged = false;
                        this.popupInstance.hide();
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            }
        } else {
            this.textBoxProductNameInstance.focus();
        }
    }

    // VALIDATIONGROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateProductName = async () => {
        var result = await ServiceProducts.doesProductNameExist(this.textBoxProductNameInstance.option('value'), this.productRecord.id);
        if (!result.hasOwnProperty("error")) {
            this.textBoxProductNameInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError("Database error!");  // callback
            return false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    width={526}
                    height={304}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div>
                                <div className="dx-field">
                                    <div className="dx-field-label pp-field-label">Name*</div>
                                    <div className="dx-field-value pp-field-value">
                                        <TextBox
                                            width='400px'
                                            onInitialized={this.textBoxProductName_OnInitialized}
                                            onFocusIn={this.textBox_OnFocusIn}
                                            onFocusOut={this.textBox_OnFocusOut}
                                            onValueChanged={this.input_ValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                                <AsyncRule validationCallback={this.validateProductName} message="Product exists" />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label pp-field-label">Shop*</div>
                                    <div className="dx-field-value pp-field-value">
                                        <SelectBox
                                            dataSource={this.state.dataSourceLocations}
                                            valueExpr="id"
                                            displayExpr="name"
                                            dropDownOptions={{ maxHeight: '200px' }}
                                            minSearchLength={0}
                                            searchEnabled={true}
                                            showClearButton={true}
                                            showDataBeforeSearch={false}
                                            width={250}
                                            onInitialized={this.selectBoxShops_OnInitialized}
                                            onFocusIn={this.selectBox_OnFocusIn}
                                            onValueChanged={this.input_ValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </SelectBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label pp-field-label">Packaging</div>
                                    <div className="dx-field-value pp-field-value">
                                        <TextBox
                                            width='200px'
                                            onInitialized={this.textBoxPackaging_OnInitialized}
                                            onFocusIn={this.textBox_OnFocusIn}
                                            onFocusOut={this.textBox_OnFocusOut}
                                            onValueChanged={this.input_ValueChanged}
                                        >
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label pp-field-label">Article No</div>
                                    <div className="dx-field-value pp-field-value">
                                        <TextBox
                                            width='200px'
                                            onInitialized={this.textBoxArticleNo_OnInitialized}
                                            onFocusIn={this.textBox_OnFocusIn}
                                            onFocusOut={this.textBox_OnFocusOut}
                                            onValueChanged={this.input_ValueChanged}
                                        >
                                        </TextBox>
                                    </div>
                                </div>
                            </div>
                            <div className="pp-buttons">
                                <div className="pp-buttons-checkbox">
                                    <CheckBox
                                        text="Keep open"
                                        onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                        onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                                    />
                                </div>
                                <div className='pp-buttons-update'>
                                    <div className="pp-button-update">
                                        <Button
                                            onInitialized={this.buttonUpdate_OnInitialized}
                                            icon={globalIcons.save}
                                            onClick={this.buttonUpdate_OnClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                   </ValidationGroup>
                    <HxPopupSaveChanges
                        onInitialized={this.popupSaveChanges_onInitialized}
                        onClick={this.popupSaveChanges_onClick}
                    />
                </Popup>
            </React.Fragment>
        );

    }
}

export default PopupProducts

