import axiosapi from '../axiosapi.js';

class ServiceJwt {

    // called from UI
    static getBearerTokenUI = async () => {
        let returnValue = null;

        const token = sessionStorage.getItem('accessToken');

        let isTokenExpired = async (token) => {
            if (!token) return true;
            try {
                let decodedToken = JSON.parse(atob(token.split('.')[1]));
                const currentTime = Date.now() / 1000;
                return decodedToken.exp < currentTime;
            } catch (error) {
                console.error('Error decoding token:', error);
                return true;
            }
        }

        if (!token || await isTokenExpired(token)) {
            await axiosapi.get('/api/Jwt/GetBearerTokenUI')
                .then(response => returnValue = response.data)
                .catch(() => returnValue = { "error": true });
        } else {
            returnValue = token;
        }

        sessionStorage.setItem('accessToken', returnValue);

        return returnValue;
    }
}

export default ServiceJwt;
