import {
    HomePage, TasksPage, EventsPage, UsersPage, UserProfilePage, UserSettingsPage,
    ShuttlesPage, ShuttlesAdminFleetPage, ShuttlesAdminDriversPage, ShuttlesAdminArtistsPage, ShuttlesAdminLocationsPage, ShuttlesUpcomingPage,
    RidersPage, RidersAdminProductsPage
} from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage,
    },
    {
        path: '/userprofile',
        element: UserProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/events',
        element: EventsPage
    },
    {
        path: '/users',
        element: UsersPage
    },
    {
        path: '/usersettings',
        element: UserSettingsPage
    },
    {
        path: '/shuttles',
        element: ShuttlesPage
    },
    {
        path: '/shuttlesadminfleet',
        element: ShuttlesAdminFleetPage
    },
    {
        path: '/shuttlesadmindrivers',
        element: ShuttlesAdminDriversPage
    },
    {
        path: '/shuttlesadminartists',
        element: ShuttlesAdminArtistsPage
    },
    {
        path: '/shuttlesadminlocations',
        element: ShuttlesAdminLocationsPage
    },
    {
        path: '/shuttlesupcoming',
        element: ShuttlesUpcomingPage
    },
    {
        path: '/riders',
        element: RidersPage
    },
    {
        path: '/ridersadminproducts',
        element: RidersAdminProductsPage
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
