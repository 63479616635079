import globalConfig from '../../config/global.js';

const mapHost = globalConfig.settings.geoCoding.geoCodingHost;
const mapKey = globalConfig.settings.geoCoding.geoCodingMapKey;

class ServiceGeoCoding {

    static getCoordinatesByAddress = async (address, postalCode, locality, countryRegion) => {
        let returnValue = {};

        let params = '';
        if (address.toString().trim() !== '') {
            params += '?addressLine=' + encodeURIComponent(address.toString().trim());
        }

        if (postalCode.toString().trim() !== '') {
            params += (params === '' ? '?' : '&') + 'postalCode=' + encodeURIComponent(postalCode.toString().trim());
        }

        if (locality.toString().trim() !== '') {
            params += (params === '' ? '?' : '&') + 'locality=' + encodeURIComponent(locality.toString().trim());
        }

        if (countryRegion.toString().trim() !== '') {
            params += (params === '' ? '?' : '&') + 'countryRegion=' + encodeURIComponent(countryRegion.toString().trim());
        }

        if (params.toString().trim() !== '') {
            params += '&key=' + encodeURIComponent(mapKey);
            await fetch(mapHost + "/Locations" + params)
                .then(response => response.json())
                .then(data => {
                    returnValue = data;
                })
                .catch(function (error) {
                    returnValue = { 'error': error.message };
                });
        }

        return returnValue;
    }

    static getRouteByDepartureArrival = async (locationDepartureLat, locationDepartureLng, locationArrivalLat, locationDArrivalLng) => {
        let returnValue = {};

        let params = '';
        if (locationDepartureLat.toString().trim() !== '' && locationDepartureLng.toString().trim() !== '' && locationArrivalLat.toString().trim() !== '' && locationDArrivalLng.toString().trim() !== '') {
            let wp1 = locationDepartureLat + "," + locationDepartureLng;
            let wp2 = locationArrivalLat + "," + locationDArrivalLng;
            params += '?waypoint.0=' + encodeURIComponent(wp1) + "&waypoint.1=" + encodeURIComponent(wp2);
        }

        if (params.toString().trim() !== '') {
            params += '&key=' + encodeURIComponent(mapKey);
            await fetch(mapHost + "/Routes/Driving" + params) // return null or data object
                .then(response => response.json())
                .then(data => {
                    returnValue = data;
                })
                .catch(function (error) {
                    returnValue = { 'error': error.message };
                });
        }
        return returnValue;
    }


    
}

export default ServiceGeoCoding;
