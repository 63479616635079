import 'devextreme/dist/css/dx.common.css';
import './dx-styles.scss';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './themes/custom/hx.material.blue.light.compact.css';

import './themes/custom/site.css';
import React from 'react';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';

//if (localStorage.getItem('theme') === undefined) {
//    localStorage.setItem('theme', 'Light'); // default theme'
//}
//if (localStorage.getItem('theme') === 'Dark') {
//    require('./themes/custom/hx.material.blue.dark.compact.css');
//    require('./themes/custom/site.css');
//} else {
//    require('./themes/custom/hx.material.blue.light.compact.css');
//    require('./themes/custom/site.css');
//}

function App() {
    const { user, loading } = useAuth();

    if (loading) {
        return <LoadPanel visible={true} />;
    }

    if (user) {
        return <Content />;
    }

    return <UnauthenticatedContent />;
}

export default function Root() {
    const screenSizeClass = useScreenSizeClass();

    return (
        <AuthProvider>
            <NavigationProvider>
                <div className={`app ${screenSizeClass}`}>
                    <App />
                </div>
            </NavigationProvider>
        </AuthProvider>
    );
}
