import React from 'react';

import DataGridProducts from './sub-components/DataGridProducts';
import HxToast from '../../../custom-components/hx-toast/HxToast';

import './RidersAdminProducts.scss';

class RidersAdminProducts extends React.Component {
    constructor(props) {
        super(props);

        // filters
        this.loadedUserFilterValue = [];
    }

    gridProducts_OnDBError = (message) => {
        HxToast.showToast(message, 'error', 'top center', 'up-stack');
    }

    render() {
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));
        if (userAuth.isSuperAdmin === false && userAuth.isRidersUser === false && userAuth.isRidersAdmin === false) {
            window.location.href = '/home';
            return "Not Authorized";
        }

       return (
            <React.Fragment>
                <div className='content-block'>
                   <DataGridProducts
                        onDBError={this.gridProducts_OnDBError}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default RidersAdminProducts
