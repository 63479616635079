import React from 'react';
import Tabs from 'devextreme-react/tabs';
import { CheckBox } from 'devextreme-react/check-box';
import { Switch } from 'devextreme-react/switch';
import * as dayJS from 'dayjs';

import DataGridShuttles from './sub-components/DataGridShuttles';
import HxToast from '../custom-components/hx-toast/HxToast';

import ServiceEvents from '../../api/services/ServiceEvents'
import ServiceShuttles from '../../api/services/ServiceShuttles';

import globalConfig from '../../config/global.js';

import './Shuttles.scss';

class Shuttles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceGigDates: [],
            tripStatusses: []
        }

        this.tabsInstance = null;

        this.checkBoxShowHideStatusWaitingInstance = null;
        this.checkBoxShowHideStatusDrivingInstance = null;
        this.checkBoxShowHideStatusFinishedInstance = null;
        this.checkBoxShowHideStatusCancelledInstance = null;
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceGigDates();
        await this.loadDataSourceTripStatusses();
    }

    // DATA
    loadDataSourceGigDates = async () => {
        let resultCount = await ServiceShuttles.getTripCounts(globalConfig.settings.shuttles.startHour)

        var data = await ServiceEvents.getEventGigDatesDropDown(true);

        if (data !== null && !data.hasOwnProperty("error")) {
            let ds = [];
            for (var i = 0; i < data.length; i++) {
                let myDate = dayJS(data[i].gigDate).format('ddd DD/MM/YY');
                if (resultCount !== null && !data.hasOwnProperty("error")) {
                    // eslint-disable-next-line no-loop-func
                    let count = resultCount.find(x => x.gigDateId === data[i].id);
                    if (count !== undefined) {
                        myDate += ` (${count.totalTrips})`;
                    }
                }
                ds.push({ id: data[i].id, text: myDate })
            }
            this.setState({ dataSourceGigDates: ds });
            if (ds.length > 0) {
                this.tabsInstance.option('selectedIndex', 0);
                await this.refGrid.reload(ds[0].id);
            }
        } else {
            this.gridShuttles_OnDBError('Database error!');
        }
    }

    loadDataSourceTripStatusses = async () => {
        var data = await ServiceShuttles.getTripStatusses();
        if (!data.hasOwnProperty('error')) {
            this.refGrid.setTripStatusses(data);
        } else {
            this.gridShuttles_OnDBError('Database error!');
        }
    }

    // GRID
    gridShuttles_OnDBError = (message) => {
        HxToast.showToast(message, 'error', 'top center', 'up-stack');
    }

    gridShuttles_OnDataLoaded = (gigDateId, tripsCount) => {
        let data = this.state.dataSourceGigDates;
        let index = data.findIndex(x => x.id === gigDateId);
        let text = data[index].text;
        const place = text.indexOf('(');
        if (place > 0) {
            data[index].text = text.substring(0, place).trim();
        }
        data[index].text += ` (${tripsCount})`;
        this.setState({ dataSourceGigDates: data });
        this.tabsInstance.repaint();
    }

    // TABS
    tabsOnInitialized = (e) => {
        this.tabsInstance = e.component;
    }

    tabsOnItemClick = async (e) => {
        await this.refGrid.reload(e.itemData.id);
        this.tabsInstance.option('selectedIndex', e.itemIndex);
    }


    // CHECKBOXES
    switchShowHideTimeLine_OnInitialized = (e) => {
        e.component.option('value', true);
    }

    switchShowHideTimeLine_OnValueChanged = (e) => {
        this.refGrid.showHideTimeLine(e.value);
    }

    checkBoxShowHideStatusWaiting_OnInitialized = (e) => {
        this.checkBoxShowHideStatusWaitingInstance = e.component;
        this.checkBoxShowHideStatusWaitingInstance.option('value', true);
    }

    checkBoxShowHideStatusDriving_OnInitialized = (e) => {
        this.checkBoxShowHideStatusDrivingInstance = e.component;
        this.checkBoxShowHideStatusDrivingInstance.option('value', true);
    }

    checkBoxShowHideStatusFinished_OnInitialized = (e) => {
        this.checkBoxShowHideStatusFinishedInstance = e.component;
        this.checkBoxShowHideStatusFinishedInstance.option('value', true);
    }

    checkBoxShowHideStatusCancelled_OnInitialized = (e) => {
        this.checkBoxShowHideStatusCancelledInstance = e.component;
        this.checkBoxShowHideStatusCancelledInstance.option('value', false);
    }

    checkBoxStatus_OnValueChanged = (e) => {
        this.refGrid.setStatusFilter(
            this.checkBoxShowHideStatusWaitingInstance.option('value'),
            this.checkBoxShowHideStatusDrivingInstance.option('value'),
            this.checkBoxShowHideStatusFinishedInstance.option('value'),
            this.checkBoxShowHideStatusCancelledInstance.option('value'),
        );
    }

    // FUNCTIONS


    render() {
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));
        if (userAuth.isSuperAdmin === false && userAuth.isShuttlesUser === false && userAuth.isShuttlesAdmin === false) {
            window.location.href = '/home';
            return "Not Authorized";
        }

        return (
            <React.Fragment>
                <div className='content-block'>
                    <div className='shuttles-grid-title'>
                        <div className='shuttles-grid-title-text'>Shuttles</div>
                        <div className='shuttles-grid-title-options'>
                            <span className='shuttles-grid-span-timeline'>
                                <span className='shuttles-grid-span-timeline-label'>Timeline:</span>
                                <span className='shuttles-grid-span-timeline-switch'>
                                    <Switch
                                        onInitialized={this.switchShowHideTimeLine_OnInitialized}
                                        onValueChanged={this.switchShowHideTimeLine_OnValueChanged}
                                    />
                                </span>
                            </span>
                            <span className="shuttles-grid-span-statusses">
                                <span className="shuttles-grid-span-statusses-label">Status:</span>
                                <span className="shuttles-grid-span-statusses-checkboxes">
                                    <CheckBox
                                        defaultValue={false}
                                        text="W"
                                        onInitialized={this.checkBoxShowHideStatusWaiting_OnInitialized}
                                        onValueChanged={this.checkBoxStatus_OnValueChanged}
                                    />
                                    <CheckBox
                                        defaultValue={false}
                                        text="D"
                                        onInitialized={this.checkBoxShowHideStatusDriving_OnInitialized}
                                        onValueChanged={this.checkBoxStatus_OnValueChanged}
                                    />
                                    <CheckBox
                                        defaultValue={false}
                                        text="F"
                                        onInitialized={this.checkBoxShowHideStatusFinished_OnInitialized}
                                        onValueChanged={this.checkBoxStatus_OnValueChanged}
                                    />
                                    <CheckBox
                                        defaultValue={false}
                                        text="C"
                                        onInitialized={this.checkBoxShowHideStatusCancelled_OnInitialized}
                                        onValueChanged={this.checkBoxStatus_OnValueChanged}
                                    />
                                </span>
                            </span>
                        </div>
                    </div>
                    <Tabs
                        dataSource={this.state.dataSourceGigDates}
                        onItemClick={this.tabsOnItemClick}
                        onInitialized={this.tabsOnInitialized}
                    />
                    <DataGridShuttles
                        ref={ref => this.refGrid = ref}
                        tripStatusses={this.state.tripStatusses}
                        onDBError={this.gridShuttles_OnDBError}
                        onDataLoaded={this.gridShuttles_OnDataLoaded}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default Shuttles
