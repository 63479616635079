import axiosapi from '../axiosapi.js';
import ServiceJwt from './ServiceJwt.js';

class ServiceRidersArtists {

    static getArtistNamesByGigDateIds = async (gigDateIds) => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/RidersArtists/GetArtistNamesByGigDateIds', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                gigDateIds: JSON.stringify(gigDateIds),
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }
    static getSubArtistNamesByArtistId = async (artistId) => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/RidersArtists/GetSubArtistNamesByArtistId', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                artistId: artistId,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }
}

export default ServiceRidersArtists;
