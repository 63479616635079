import React from 'react';

import DataGridUsers from './sub-components/DataGridUsers';
import HxToast from '../custom-components/hx-toast/HxToast';

import './Users.scss'

class Users extends React.Component {
    constructor(props) {
        super(props);

        this.users = [];
    }

    gridUsers_OnDBError = (message) => {
        HxToast.showToast(message, 'error', 'top center', 'up-stack');
    }

    render() {
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));
        if (userAuth.isSuperAdmin === false) {
            window.location.href = '/home';
            return "Not Authorized";
        }

        return (
            <React.Fragment>
                <div className='content-block'>
                    <DataGridUsers
                        onDBError={this.gridUsers_OnDBError}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default Users

