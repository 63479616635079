import axiosapi from '../axiosapi.js';
import ServiceJwt from './ServiceJwt.js';

class ServiceShuttlesArtists {


    static getArtistsInfo = async () => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/ShuttlesArtists/GetArtistsInfo', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getArtistsDropDown = async () => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/ShuttlesArtists/GetArtistsDropDown', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getArtist = async (id) => {
        let returnValue = null;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/ShuttlesArtists/GetArtist', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static createArtist = async (model) => {
        let returnValue = -1;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.post('/api/ShuttlesArtists/CreateArtist', JSON.stringify(model), {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });
        return returnValue;
    }


    static updateArtist = async (model) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.put('/api/ShuttlesArtists/UpdateArtist', JSON.stringify(model), {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static deleteArtist = async (id) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.delete('/api/ShuttlesArtists/DeleteArtist', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static canArtistBeDeleted = async (id) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/ShuttlesArtists/CanArtistBeDeleted', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesArtistNameExist = async (artistName, artistIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/ShuttlesArtists/DoesArtistNameExist', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                artistName: artistName,
                artistIdToExclude: artistIdToExclude,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }




    static getArtistContactsByArtistId = async (artistId) => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/ShuttlesArtists/GetArtistContactsByArtistId', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                artistId: artistId,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getAvailableArtistContactsForArtist = async (artistContacts, reverseNameOrder) => {
        let returnValue = null;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/ShuttlesArtists/GetAvailableArtistContactsForArtist', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                artistContacts: artistContacts,
                reverseNameOrder: reverseNameOrder,
                eventId: userSettingsGeneral.currentEventId,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getArtistContact = async (id) => {
        let returnValue = null;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/ShuttlesArtists/GetArtistContact', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static createArtistContact = async (model) => {
        let returnValue = -1;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.post('/api/ShuttlesArtists/CreateArtistContact', JSON.stringify(model), {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });
        return returnValue;
    }


    static updateArtistContact = async (model) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.put('/api/ShuttlesArtists/UpdateArtistContact', JSON.stringify(model), {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }
    
    static deleteArtistContact = async (id) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.delete('/api/ShuttlesArtists/DeleteArtistContact', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesArtistContactFirstNameExist = async (firstName, lastName, artistContactIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/ShuttlesArtists/DoesArtistContactNameExist', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                firstName: firstName,
                lastName: lastName,
                artistContactIdToExclude: artistContactIdToExclude,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesArtistContactLastNameExist = async (firstName, lastName, artistContactIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/ShuttlesArtists/DoesArtistContactNameExist', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                firstName: firstName,
                lastName: lastName,
                artistContactIdToExclude: artistContactIdToExclude,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesArtistContactEmailExist = async (email, artistContactIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/ShuttlesArtists/DoesArtistContactEmailExist', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                email: email,
                artistContactIdToExclude: artistContactIdToExclude,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }
}

export default ServiceShuttlesArtists;
