import axiosapi from '../axiosapi.js';
import ServiceJwt from './ServiceJwt.js';

class ServiceEvents {

    static getEventsInfo = async () => {
        let returnValue = [];

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Events/GetEventsInfo', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getEvent = async (id) => {
        let returnValue = null;

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Events/GetEvent', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                id: id
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getEventLocationId = async () => {
        let returnValue = null;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Events/GetEventLocationId', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                id: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getEventGigDatesDropDown = async (forShuttles) => {
        let returnValue = null;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Events/GetEventGigDatesDropDown', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                forShuttles: forShuttles,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static createEvent = async (model) => {
        let returnValue = -1;

        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.post('/api/Events/CreateEvent', JSON.stringify(model), {
            params: {
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }

        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static updateEvent = async (model) => {
        let returnValue = false;

        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.put('/api/Events/UpdateEvent', JSON.stringify(model), {
            params: {
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static deleteEvent = async (id) => {
        let returnValue = false;

        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.delete('/api/Events/DeleteEvent', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                id: id,
                userAuthId: userAuth.id
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getTempGigDatesByDateInterval = async (startDate, endDate) => {
        let returnValue = null;

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Events/GetTempGigDatesByDateInterval', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                startDate: startDate,
                endDate: endDate
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getGigDatesByEventId = async () => {
        let returnValue = null;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Events/GetGigDatesByEventId', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                eventId: userSettingsGeneral.currentEventId,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesEventNameExist = async (name, eventIdToExclude) => {
        let returnValue = false;

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Events/DoesEventNameExist', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                name: name,
                eventIdToExclude: eventIdToExclude,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }
     
}

export default ServiceEvents;
