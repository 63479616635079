import axiosapi from '../axiosapi.js';
import ServiceJwt from './ServiceJwt.js';

class ServiceUserSettings {

    static getUsersSettingsGeneralInfoByUserId = async (userId) => {
        let returnValue = null;

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/UserSettings/GetUsersSettingsGeneralInfoByUserId', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                userId: userId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getUsersSettingsInfoByUserId = async (userId) => {
        let returnValue = null;

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/UserSettings/GetUsersSettingsInfoByUserId', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                userId: userId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static updateUserSettingsGeneral = async (model) => {
        let returnValue = false;

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.put('/api/UserSettings/UpdateUsersSettingsGeneral', JSON.stringify(model), {
            headers: {
                'Content-Type': 'application/json',
               Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

}

export default ServiceUserSettings;
