import axiosapi from '../axiosapi.js';
import ServiceJwt from './ServiceJwt.js';

class ServiceStats {

    static getTripsStats = async () => {
        let returnValue = [];

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Stats/GetTripsStats', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getDriversStats = async () => {
        let returnValue = [];

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Stats/GetDriversStats', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken') }` },
            params: {
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }



 
}

export default ServiceStats;
