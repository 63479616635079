import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, EmailRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import SelectBox from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import ColorBox from 'devextreme-react/color-box';
import DataGrid, {Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, StateStoring, Sorting, LoadPanel} from 'devextreme-react/data-grid';
import HtmlEditor from 'devextreme-react/html-editor';

import globalIcons from '../../../../../config/globalIcons.js';

import HxPopupSaveChanges from '../../../../custom-components/hx-popup-save-changes/HxPopupSaveChanges'

import ServiceDrivers from '../../../../../api/services/ServiceDrivers'
import ServiceColors from '../../../../../api/services/ServiceColors'
import Converters from '../../../../../helpers/Converters'

import globalConfig from '../../../../../config/global.js';

import './PopupDrivers.scss'

// props
//
// callables
//      preload
// callbacks
//      onInitialized
//      onCreate
//      onUpdate
//      onDBError

class PopupDrivers extends React.Component {
    constructor(props) {
        super(props);

        this.driverId = -1;

        this.state = {
            driverAvailabilityDataSource: [],
            previewText: '',
        };
 
        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.textBoxFirstNameInstance = null;
        this.textBoxLastNameInstance = null;
        this.textBoxEmailInstance = null;
        this.textBoxTelInstance = null;
        this.htmlEditorNotesInstance = null;
        this.textBoxShortNameInstance = null;
        this.colorBoxColorHexInstance = null;
        this.colorBoxForeColorHexInstance = null;
        this.selectBoxPatternsInstance = null;
        this.colorBoxPatternColorHexInstance = null;

        this.gridInstance = null;

        this.driverRecord = {};
 
        this.backgroundPatterns = [];

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.dataHasChanged = false;

        this.popupSaveChangesInstance = null;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    onCreate = (success) => {
        if (this.props.onCreate) {
            this.props.onCreate(success);  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    }

    // CALLABLES
    preload = async (id) => {
        this.userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        this.driverId = id;

        await this.loadDataSourceBackGroundPatterns();
        await this.fillPopup(id);
    }

    // EVENTS


    // DATA
    loadDataSourceDriver = async (driverId) => {
        var data = await ServiceDrivers.getDriver(driverId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.driverRecord = data;
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    loadDataSourceBackGroundPatterns = async () => {
        var data = await ServiceColors.getBackGroundPatternsInfo();
        if (data !== null && !data.hasOwnProperty("error")) {
            var rec = {
                id: -1,
                patternName: 'None',
                cssBackGround: '',
                cssBackGroundImage: '',
                cssBackGroundSize: '',
                cssBackGroundPosition: ''
            }
            data.unshift(rec);
            this.backgroundPatterns = data;
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        // set image here, record is loaded on preload
        var image = document.getElementById('driverImage');
        if (this.driverRecord.avatarUrl != null) {
            image.src = globalConfig.settings.images.driverImagesPath + this.driverRecord.avatarUrl;
        } else {
            image.src = globalConfig.settings.images.driverImagesPath + "default_250.jpg";
        }

        if (this.driverId === -1) {
            // new driver
            this.checkBoxKeepOpenInstance.option('visible', true);
            this.setPopupTitle();
        } else {
            // update driver
            this.checkBoxKeepOpenInstance.option('visible', false);
            this.setPopupTitle();
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '80px');
    }

    popup_OnShown = async (e) => {
        this.textBoxFirstNameInstance.focus();
    }

    popup_OnHiding = (e) => {
        if (this.dataHasChanged && this.userSettingsGeneral.popupWarnIfDataHasChanged === true) {
            e.cancel = true;
            this.popupSaveChangesInstance.show();
        }
    }

    fillPopup = async (driverId) => {
        await this.loadDataSourceDriver(driverId);

        this.textBoxFirstNameInstance.option('value', this.driverRecord.firstName);
        this.textBoxLastNameInstance.option('value', this.driverRecord.lastName);
        this.textBoxEmailInstance.option('value', this.driverRecord.email);
        this.textBoxTelInstance.option('value', this.driverRecord.tel);
        this.htmlEditorNotesInstance.option('value', this.driverRecord.notes);

        this.textBoxShortNameInstance.option('value', this.driverRecord.shortName);
        this.colorBoxColorHexInstance.option('value', this.driverRecord.colorHex);
        this.colorBoxForeColorHexInstance.option('value', this.driverRecord.foreColorHex);
        this.selectBoxPatternsInstance.option('value', this.driverRecord.patternId !== null ? this.driverRecord.patternId : -1);
        this.colorBoxPatternColorHexInstance.option('value', this.driverRecord.patternColorHex);

        this.setState({ driverAvailabilityDataSource: this.driverRecord.driverAvailabilities });

        this.dataHasChanged = false;

        await this.validationGroupInstance.validate();
    }

    resetPopup = async () => {
        this.driverId = -1;

        await this.fillPopup(this.driverId);

        this.setPopupTitle();

        this.checkBoxKeepOpenInstance.option('visible', true)

        await this.validationGroupInstance.validate();

        this.textBoxFirstNameInstance.focus();
    }

    setPopupTitle = () => {
        if (this.driverId === -1) {
            this.popupInstance.option('title', 'Create driver' + (this.dataHasChanged === true ? '*' : ''));
        } else {
            this.popupInstance.option('title', 'Update ' + this.driverRecord.firstName + ' ' + this.driverRecord.lastName + (this.dataHasChanged === true ? '*' : ''));
        }
    }

    // POPUP SAVE CHANGES
    popupSaveChanges_onInitialized = (instance) => {
        this.popupSaveChangesInstance = instance;
    }

    popupSaveChanges_onClick = async (value) => {
        this.popupSaveChangesInstance.hide();

        switch (value.toUpperCase()) {
            case "YES":
                await this.updateDriver();
                break;
            case "NO":
                this.dataHasChanged = false;
                this.popupInstance.hide();
                break;
            case "CANCEL":
                break;
            default:
        }
    }

    // ALL INPUTS VALUE CHANGED
    input_ValueChanged = (e) => {
        this.dataHasChanged = true;
        this.setPopupTitle();
    }

    // ALL TEXTBOXES FOCUS IN AND OUT
    textBox_OnFocusIn = (e) => {
        if (this.userSettingsGeneral.inputFocusInSelectAll === true) {
            // select all text
            e.element.querySelector('.dx-texteditor-input').select();
        }
    }

    textBox_OnFocusOut = (e) => {
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
    }

    // TEXTBOX FIRST NAME
    textBoxFirstName_OnInitialized = (e) => {
        this.textBoxFirstNameInstance = e.component;
    }

    // TEXTBOX LAST NAME
    textBoxLastName_OnInitialized = (e) => {
        this.textBoxLastNameInstance = e.component;
    }

    // TEXTBOX EMAIL
    textBoxEmail_OnInitialized = (e) => {
        this.textBoxEmailInstance = e.component;
    }

    // TEXTBOX TEL
    textBoxTel_OnInitialized = (e) => {
        this.textBoxTelInstance = e.component;
    }

    // HTML EDITOR
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }
    // TEXTBOX SHORT NAME
    textBoxShortName_OnInitialized = (e) => {
        this.textBoxShortNameInstance = e.component;
    }

    textBoxShortName_OnValueChanged = (e) => {
        this.setState({ previewText: e.value });
        this.input_ValueChanged(e);
    }

    textBoxShortName_OnFocusOut = (e) => {
        e.component.option('value', e.component.option('value').toUpperCase());
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
    }

    buttonAutoShortName_OnClick = async (e) => {
        let shortName = '';

        if (this.textBoxFirstNameInstance.option('value') !== null && this.textBoxFirstNameInstance.option('value').trim().length !== 0) {
            shortName = this.textBoxFirstNameInstance.option('value').slice(0, 1).toUpperCase();
        }

        if (this.textBoxLastNameInstance.option('value') != null && this.textBoxLastNameInstance.option('value').trim().length !== 0) {
            shortName += this.textBoxLastNameInstance.option('value').slice(0, 1).toUpperCase();
            if (this.textBoxLastNameInstance.option('value').trim().length > 1) {
                shortName += this.textBoxLastNameInstance.option('value').slice(1, 2).toUpperCase();
            }
        }

        if (shortName.trim().length !== 0) {
            let i = 0;
            let exists = true;
            let err = false;
            let newShortName = shortName;

            while (i < 100 && exists === true) {
                newShortName = shortName + (i > 0 ? i : '');
                var result = await ServiceDrivers.doesShortNameExist(newShortName, this.driverRecord.id);
                if (result !== null && !result.hasOwnProperty("error")) {
                    exists = result;
                } else {
                    err = true;
                    break;
                }
                i++;
            }

            if (err === false && exists === false) {
                this.textBoxShortNameInstance.option('value', newShortName);
            }
        }
    }

    // COLORBOX COLOR
    colorBoxColorHex_OnInitialized = (e) => {
        this.colorBoxColorHexInstance = e.component;
    }

    colorBoxColorHex_OnValueChanged = (e) => {
        this.setPreviewStyle();
        this.input_ValueChanged(e);
    }

    // COLORBOX FORECOLOR
    colorBoxForeColorHex_OnInitialized = (e) => {
        this.colorBoxForeColorHexInstance = e.component;
    }

    colorBoxForeColorHex_OnValueChanged = (e) => {
        this.setPreviewStyle();
        this.input_ValueChanged(e);
    }

    // COLORBOX FORECOLOR
    colorBoxPatternColorHex_OnInitialized = (e) => {
        this.colorBoxPatternColorHexInstance = e.component;
    }

    colorBoxPatternColorHex_OnValueChanged = (e) => {
        this.setPreviewStyle();
        this.input_ValueChanged(e);
    }

    // SELECTBOX PATTERNS
    selectBoxPatterns_OnInitialized = (e) => {
        this.selectBoxPatternsInstance = e.component;
    }

    selectBoxPatterns_OnValueChanged = (e) => {
        this.setPreviewStyle();
        this.input_ValueChanged(e);
    }


    // DATAGRID
    gridOnInitialized = (e) => {
        this.gridInstance = e.component;
        this.gridInstance.option('loadPanel.enabled', false); 
    }

    gridOnCellClick = async (e) => {
        // if white space is clicked then e.column is undefined
        if (e.rowIndex === -1) {
            return;
        }

        if (e.column.dataField === 'allDay') {
            if (this.gridInstance.cellValue(e.rowIndex, 'isAvailable') === true) {
                if (e.value) {
                    this.gridInstance.cellValue(e.rowIndex, 'availableFrom', null);
                    await this.gridInstance.saveEditData();
                    this.gridInstance.cellValue(e.rowIndex, 'availableTo', null);
                    await this.gridInstance.saveEditData();
                    this.gridInstance.cellValue(e.rowIndex, 'availableFrom2', null);
                    await this.gridInstance.saveEditData();
                    this.gridInstance.cellValue(e.rowIndex, 'availableTo2', null);
                    await this.gridInstance.saveEditData();
                } else {
                    var dt = this.gridInstance.cellValue(e.rowIndex, 'availableDate');
                    var dtTo = this.gridInstance.cellValue(e.rowIndex, 'availableDate');
                    dt.setHours(0, 0, 0, 0);
                    dtTo.setHours(23, 59, 0, 0);
                    this.gridInstance.cellValue(e.rowIndex, 'availableFrom', dt);
                    await this.gridInstance.saveEditData();
                    this.gridInstance.cellValue(e.rowIndex, 'availableTo', dtTo);
                    await this.gridInstance.saveEditData();
                }
            } else {
                this.gridInstance.cellValue(e.rowIndex, 'allDay', true);
                await this.gridInstance.saveEditData();
            }
            this.dataHasChanged = true;
            this.setPopupTitle();
        } else if (e.column.dataField === 'isAvailable') {
            this.gridInstance.cellValue(e.rowIndex, 'allDay', true);
            await this.gridInstance.saveEditData();
            this.gridInstance.cellValue(e.rowIndex, 'reserve', false);
            await this.gridInstance.saveEditData();
            this.gridInstance.cellValue(e.rowIndex, 'availableFrom', null);
            await this.gridInstance.saveEditData();
            this.gridInstance.cellValue(e.rowIndex, 'availableTo', null);
            await this.gridInstance.saveEditData();
            this.gridInstance.cellValue(e.rowIndex, 'availableFrom2', null);
            await this.gridInstance.saveEditData();
            this.gridInstance.cellValue(e.rowIndex, 'availableTo2', null);
            await this.gridInstance.saveEditData();
            this.dataHasChanged = true;
            this.setPopupTitle();
        } else if (e.column.dataField === 'reserve') {
            if (this.gridInstance.cellValue(e.rowIndex, 'isAvailable') === false) {
                this.gridInstance.cellValue(e.rowIndex, 'reserve', false);
                await this.gridInstance.saveEditData();
            }
            this.dataHasChanged = true;
            this.setPopupTitle();
        }
    }

    gridOnEditorPreparing = (e) => {
        if (this.gridInstance.cellValue(e.row.rowIndex, 'allDay') === true) {
            if (e.dataField === 'availableFrom' || e.dataField === 'availableTo' || e.dataField === 'availableFrom2' || e.dataField === 'availableTo2') {
                e.editorOptions.disabled = true;
            }
        } else {
            if (this.gridInstance.cellValue(e.row.rowIndex, 'availableFrom') === null || this.gridInstance.cellValue(e.row.rowIndex, 'availableTo') === null) {
                if (e.dataField === 'availableFrom2' || e.dataField === 'availableTo2') {
                    e.editorOptions.disabled = true;
                }
            }
        }
    }

    setPreviewStyle = () => {
        var bg = '#FFFFFFFF';
        var fg = '#000000FF'

        if (this.colorBoxColorHexInstance.option('value') !== null && this.colorBoxColorHexInstance.option('value') !== '') {
            bg = this.colorBoxColorHexInstance.option('value');
        }
        if (this.colorBoxForeColorHexInstance.option('value') !== null && this.colorBoxForeColorHexInstance.option('value') !== '') {
            fg = this.colorBoxForeColorHexInstance.option('value');
        }
        var html = '.hx-preview-style {background-color: ' + bg + '  !important; color: ' + fg + '; ';

        if (this.selectBoxPatternsInstance.option('value') !== -1 && this.selectBoxPatternsInstance.option('value') !== null) {
            // pattern
            if (this.colorBoxPatternColorHexInstance.option('value') !== null && this.colorBoxPatternColorHexInstance.option('value') !== '') {
                // use pattern
                var patternColor = this.colorBoxPatternColorHexInstance.option('value');
                var patternId = this.selectBoxPatternsInstance.option('value');
                var pattern = this.backgroundPatterns.find(x => x.id === patternId);
                const searchRegExp = /\[rgba\]/gi;

                if (pattern.cssBackGround !== null) {
                    html += 'background: ' + pattern.cssBackGround.replace(searchRegExp, patternColor) + '; ';
                }
                if (pattern.cssBackGroundImage !== null) {
                    html += 'background-image: ' + pattern.cssBackGroundImage.replace(searchRegExp, patternColor) + '; ';
                }
                if (pattern.cssBackGroundSize !== null) {
                    html += 'background-size: ' + pattern.cssBackGroundSize + '; ';
                }
                if (pattern.cssBackGroundPosition !== null) {
                    html += 'background-position: ' + pattern.cssBackGroundPosition + '; ';
                }
            }
        }

        html += '}';

        let sheet = document.createElement('style');
        sheet.id = 'style-preview';
        if (sheet.innerHTML.indexOf('hx-preview-style') === -1) {
            sheet.innerHTML = html;
        }

        var child = document.getElementById('style-preview');
        if (child) {
            document.body.removeChild(child);
        }
        document.body.appendChild(sheet);
    };

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '120px' : '80px'));
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateDriver();
    }

    updateDriver = async () => {
        // run async validation rules again because of bug in dxValidationGroup
        var resShortName = await this.validateShortName();
        var resFirstName = await this.validateDriverFirstName();
        var resLastName = await this.validateDriverLastName();

        var res = await this.validationGroupInstance.validate();
        var valid = this.validateDriverAvailabilities();
 
        if (res.isValid === true
            && valid === true
            && resShortName === true
            && resFirstName === true
            && resLastName === true) {

            this.driverRecord.firstName = this.textBoxFirstNameInstance.option('value');
            this.driverRecord.lastName = this.textBoxLastNameInstance.option('value');
            this.driverRecord.shortName = this.textBoxShortNameInstance.option('value');
            this.driverRecord.email = this.textBoxEmailInstance.option('value');
            this.driverRecord.tel = this.textBoxTelInstance.option('value');
            this.driverRecord.notes = this.htmlEditorNotesInstance.option('value');

            this.driverRecord.colorHex = this.colorBoxColorHexInstance.option('value');
            this.driverRecord.foreColorHex = this.colorBoxForeColorHexInstance.option('value');
            this.driverRecord.patternColorHex = this.colorBoxPatternColorHexInstance.option('value');
            this.driverRecord.patternId = (this.selectBoxPatternsInstance.option('value') !== -1 ? this.selectBoxPatternsInstance.option('value') : null);

            var rows = this.gridInstance.getDataSource()._items;
            var dsDriverAvailabilities = [];
            for (var i = 0; i < rows.length; i++) {
                dsDriverAvailabilities.push({
                    id: rows[i].id,
                    driverId: rows[i].driverId,
                    eventId: this.driverRecord.eventId,
                    isAvailable: rows[i].isAvailable,
                    allDay: rows[i].allDay,
                    availableDate: await Converters.ConvertDateToSqlDate(rows[i].availableDate),
                    availableFrom: await Converters.ConvertDateToSqlDate(rows[i].availableFrom),
                    availableTo: await Converters.ConvertDateToSqlDate(rows[i].availableTo),
                    availableFrom2: await Converters.ConvertDateToSqlDate(rows[i].availableFrom2),
                    availableTo2: await Converters.ConvertDateToSqlDate(rows[i].availableTo2),
                    reserve: rows[i].reserve,
                })
            }
            this.driverRecord.driverAvailabilities = dsDriverAvailabilities;

            if (this.driverId === -1) {
                // new driver
                var resultDriver = await ServiceDrivers.createDriver(this.driverRecord);
                if (!resultDriver.hasOwnProperty("error")) {
                    this.onCreate(resultDriver); // callback
                    if (resultDriver !== -1) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.dataHasChanged = false;
                            this.popupInstance.hide();
                        } else {
                            await this.resetPopup();
                            this.setPopupTitle();
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            } else {
                // update driver
                var resultDriverUpdate = await ServiceDrivers.updateDriver(this.driverRecord);
                if (!resultDriverUpdate.hasOwnProperty("error")) {
                    this.onUpdate(resultDriverUpdate); // callback
                    if (resultDriverUpdate === true) {
                        this.dataHasChanged = false;
                        this.popupInstance.hide();
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            }
        } else {
            this.textBoxFirstNameInstance.focus();
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateShortName = async () => {
        var result = await ServiceDrivers.doesShortNameExist(this.textBoxShortNameInstance.option('value'), this.driverRecord.id);
        if (result !== null && !result.hasOwnProperty("error")) {
            this.textBoxShortNameInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError();  // callback
            return false;
        }
    }

    validateDriverFirstName = async () => {
        if (this.textBoxFirstNameInstance.option('value') === null || this.textBoxLastNameInstance.option('value') === null) {
            return true;
        }
        var result = await ServiceDrivers.doesDriverFirstNameExist(this.textBoxFirstNameInstance.option('value'), this.textBoxLastNameInstance.option('value'), this.driverRecord.id);
        if (result !== null && !result.hasOwnProperty("error")) {
            this.textBoxFirstNameInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError();  // callback
            return false;
        }
    }

    validateDriverLastName = async () => {
        if (this.textBoxFirstNameInstance.option('value') === null || this.textBoxLastNameInstance.option('value') === null) {
            return true;
        }

        var result = await ServiceDrivers.doesDriverLastNameExist(this.textBoxFirstNameInstance.option('value'), this.textBoxLastNameInstance.option('value'), this.driverRecord.id);
        if (result !== null && !result.hasOwnProperty("error")) {
            this.textBoxLastNameInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError();  // callback
            return false;
        }
    }

    validateDriverAvailabilities = () => {

        var result1 = true;
        var result2 = true;
        var rows = this.gridInstance.getVisibleRows();

        for (var i = 0; i < rows.length; i++) {
            if (rows[i].data.isAvailable === true && rows[i].data.allDay !== true) {
                if (rows[i].data.availableFrom === null) {
                    result1 = false;
                    this.gridInstance.getCellElement(rows[i].rowIndex, 'availableFrom').classList.add('psad-row-error');
                }
                if (rows[i].data.availableTo === null) {
                    result1 = false;
                    this.gridInstance.getCellElement(rows[i].rowIndex, 'availableTo').classList.add('psad-row-error');
                }

                if ((rows[i].data.availableFrom !== null && rows[i].data.availableTo !== null) && ((rows[i].data.availableFrom2 !== null && rows[i].data.availableTo2 === null) || (rows[i].data.availableFrom2 === null && rows[i].data.availableTo2 !== null))) {
                    if (rows[i].data.availableFrom2 === null) {
                        result2 = false;
                        this.gridInstance.getCellElement(rows[i].rowIndex, 'availableFrom2').classList.add('psad-row-error');
                    }
                    if (rows[i].data.availableTo2 === null) {
                        result2 = false;
                        this.gridInstance.getCellElement(rows[i].rowIndex, 'availableTo2').classList.add('psad-row-error');
                    }
                }

                if (result1 === true) {
                    if (rows[i].data.availableFrom > rows[i].data.availableTo) {
                        result1 = false;
                        this.gridInstance.getCellElement(rows[i].rowIndex, 'availableFrom').classList.add('psad-row-error');
                        this.gridInstance.getCellElement(rows[i].rowIndex, 'availableTo').classList.add('psad-row-error');
                    }
                }

                if (result2 === true) {
                    if (rows[i].data.availableFrom2 > rows[i].data.availableTo2) {
                        result2 = false;
                        this.gridInstance.getCellElement(rows[i].rowIndex, 'availableFrom2').classList.add('psad-row-error');
                        this.gridInstance.getCellElement(rows[i].rowIndex, 'availableTo2').classList.add('psad-row-error');
                    }
                }
            }
        }

        return (result1 === true && result2 === true);
    }

    // RENDERING
    renderItem = (e) => {
        var style = {};
        const searchRegExp = /\[rgba\]/gi;

        style.width = '40px';
        style.height = '30px';
        style.backgroundColor = '#FFFFFF';
        style.color = '#000000';
        style.marginRight = '10px';
        if (e.cssBackGround != null) {
            style.background = e.cssBackGround.replace(searchRegExp, '#000000');
        }
        if (e.cssBackGroundImage != null) {
            style.backgroundImage = e.cssBackGroundImage.replace(searchRegExp, '#000000');
        }
        if (e.cssBackGroundSize != null) {
            style.backgroundSize = e.cssBackGroundSize;
        }
        if (e.cssBackGroundPosition != null) {
            style.backgroundPosition = e.cssBackGroundPosition;
        }

        return <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div style={style}></div>
            <div style={{ paddingTop: '4px' }}>{e.patternName}</div>
        </div>;
    }

    // RENDERING
    renderForeColorButtons = () => {
        return <div className="psad-buttons-forecolor">
            <div className="psad-button-forecolor-black" onClick={this.buttonBlack_OnClick}>B</div>
            <div className="psad-button-forecolor-white" onClick={this.buttonWhite_OnClick}>W</div>
        </div>;
    }

    buttonBlack_OnClick = () => {
        this.colorBoxForeColorHexInstance.option('value', '#000000FF')
    }

    buttonWhite_OnClick = () => {
        this.colorBoxForeColorHexInstance.option('value', '#FFFFFFFF')
    }


    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={1041}
                    height={667}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div>
                                <div className="psad-top-div">
                                    <div className="psad-top-left-div">
                                        <div className="psad-avatar">
                                            <img id="driverImage" src="" alt="" />
                                        </div>
                                    </div>
                                    <div className="psad-top-middle-div">
                                        <div className="psad-dx-field">
                                            <div className="dx-field-label psad-top-label">First Name*</div>
                                            <div className="dx-field-value psad-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onInitialized={this.textBoxFirstName_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateDriverFirstName} message="Full name exists" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psad-dx-field">
                                            <div className="dx-field-label psad-top-label">Last Name*</div>
                                            <div className="dx-field-value psad-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onInitialized={this.textBoxLastName_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateDriverLastName} message="Full name exists" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psad-dx-field">
                                            <div className="dx-field-label psad-top-label">Email</div>
                                            <div className="dx-field-value psad-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onInitialized={this.textBoxEmail_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <EmailRule
                                                            message="Invalid email" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psad-dx-field psad-dx-field-tel">
                                            <div className="dx-field-label psad-top-label">Tel</div>
                                            <div className="dx-field-value psad-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onInitialized={this.textBoxTel_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                </TextBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="psad-top-right-div">
                                        <div className="psad-dx-field-notes">
                                            <div className="dx-field-value psad-top-input-notes">
                                                <HtmlEditor
                                                    width='100%'
                                                    height='200px'
                                                    placeholder='Notes'
                                                    onInitialized={this.htmlEditorNotes_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                </HtmlEditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="psad-second-div">
                                <div className="psad-second-left-div">
                                    <div className="dx-card psad-second-left-card">
                                        <div className="psad-header-div">Display</div>
                                        <div className="psad-dx-field">
                                            <div className='psad-second-preview hx-preview-style'>{this.state.previewText}</div>
                                        </div>
                                        <div className="psad-dx-field">
                                            <div className="dx-field-label psad-second-label">Initials*</div>
                                            <div className="dx-field-value psad-second-input">
                                                <TextBox
                                                    width='100px'
                                                    maxLength={5}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onInitialized={this.textBoxShortName_OnInitialized}
                                                    onValueChanged={this.textBoxShortName_OnValueChanged}
                                                    onFocusOut={this.textBoxShortName_OnFocusOut}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateShortName} message="Initials exist" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                                <div className='psad-second-auto-button'><Button text="Auto" stylingMode="outlined" width="64px" height="34px" onClick={this.buttonAutoShortName_OnClick} /></div>
                                            </div>
                                        </div>
                                        <div className="psad-dx-field">
                                            <div className="dx-field-label psad-second-label">Background*</div>
                                            <div className="dx-field-value psad-second-input">
                                                <ColorBox
                                                    width='200px'
                                                    onInitialized={this.colorBoxColorHex_OnInitialized}
                                                    onValueChanged={this.colorBoxColorHex_OnValueChanged}
                                                    editAlphaChannel={true}
                                                >
                                                </ColorBox>
                                            </div>
                                        </div>
                                        <div className="psad-dx-field">
                                            <div className="dx-field-label psad-second-label">Text*</div>
                                            <div className="dx-field-value psad-second-input">
                                                <ColorBox
                                                    width='200px'
                                                    onInitialized={this.colorBoxForeColorHex_OnInitialized}
                                                    onValueChanged={this.colorBoxForeColorHex_OnValueChanged}
                                                    editAlphaChannel={true}
                                                >
                                                </ColorBox>
                                                {this.renderForeColorButtons()}
                                            </div>
                                        </div>
                                        <div className="psad-dx-field">
                                            <div className="dx-field-label psad-second-label">Pattern*</div>
                                            <div className="dx-field-value psad-second-input">
                                                <SelectBox
                                                    dataSource={this.backgroundPatterns}
                                                    valueExpr="id"
                                                    displayExpr="patternName"
                                                    itemRender={this.renderItem}
                                                    width='200px'
                                                    onInitialized={this.selectBoxPatterns_OnInitialized}
                                                    onValueChanged={this.selectBoxPatterns_OnValueChanged}
                                                />
                                            </div>
                                        </div>
                                        <div className="psad-dx-field">
                                            <div className="dx-field-label psad-second-label">Pattern color*</div>
                                            <div className="dx-field-value psad-second-input">
                                                <ColorBox
                                                    width='200px'
                                                    onInitialized={this.colorBoxPatternColorHex_OnInitialized}
                                                    onValueChanged={this.colorBoxPatternColorHex_OnValueChanged}
                                                    editAlphaChannel={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="dx-card psad-second-right-card">
                                    <div className="psad-header-div">Availability</div>
                                        <DataGrid
                                            ref={ref => this.refGrid = ref}
                                            dataSource={this.state.driverAvailabilityDataSource}
                                            key='id'
                                            height='100%'
                                            allowColumnReordering={false}
                                            allowColumnResizing={false}
                                            cacheEnabled={true}
                                            columnAutoWidth={false}
                                            columnResizingMode='widget'
                                            filterSyncEnabled={true}
                                            focusedRowEnabled={false}
                                            hoverStateEnabled={false}
                                            noDataText='No availabilities found.'
                                            remoteOperations={true}
                                            repaintChangesOnly={true}
                                            showBorders={true}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            wordWrapEnabled={false}
                                            onInitialized={this.gridOnInitialized}
                                            onCellClick={this.gridOnCellClick}
                                            onEditorPreparing={this.gridOnEditorPreparing}
                                        >
                                            <Editing
                                                mode="cell"
                                                allowUpdating={true}
                                                allowAdding={false}
                                                allowDeleting={false} />
                                            <KeyboardNavigation
                                                enabled={false}
                                                editOnKeyPress={true}
                                                enterKeyAction='moveFocus'
                                                enterKeyDirection='column' />
                                            <LoadPanel enabled={false} />
                                            <StateStoring enabled={false} type="localStorage" storageKey="storageDriverAvailabilitiesDataGrid" />
                                            <Sorting mode='multiple' />
                                            <ColumnChooser enabled={false} />
                                            <Paging enabled={false} defaultPageSize={20} />
                                            <FilterPanel visible={false} />
                                            <FilterRow visible={false} />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={false} />
                                            <SearchPanel visible={false} />
                                            <Scrolling
                                                mode="standard"
                                                columnRenderingMode="standard"
                                                rowRenderingMode="standard"
                                                showScrollBar='onHover'
                                                preloadEnabled={true}
                                            />
                                            <Column dataField="id" dataType="number" visible={false} />
                                            <Column dataField="driverId" dataType="number" visible={false} />
                                            <Column allowSorting={false} allowResizing={false} dataField="isAvailable" dataType="boolean" caption='' width={40} />
                                            <Column allowSorting={false} allowResizing={false} allowEditing={false} dataField="availableDate" dataType="date" caption='Date' format="dd/MM/yyyy" width={100}>
                                                <ValidationRule type="required" message="Required" />
                                            </Column>
                                            <Column allowSorting={false} allowResizing={false} dataField="allDay" dataType="boolean" width={60} />
                                            <Column allowSorting={false} allowResizing={false} dataField="availableFrom" dataType="datetime" format="HH:mm" caption='From' editorOptions={{ type: 'time', displayFormat: 'HH:mm', pickerType: 'native' }} width={77} />
                                            <Column allowSorting={false} allowResizing={false} dataField="availableTo" dataType="datetime" format="HH:mm" caption='To' editorOptions={{ type: 'time', displayFormat: 'HH:mm', pickerType: 'native' }} width={77} />
                                            <Column allowSorting={false} allowResizing={false} dataField="availableFrom2" dataType="datetime" format="HH:mm" caption='From' editorOptions={{ type: 'time', displayFormat: 'HH:mm', pickerType: 'native' }} width={77} />
                                            <Column allowSorting={false} allowResizing={false} dataField="availableTo2" dataType="datetime" format="HH:mm" caption='To' editorOptions={{ type: 'time', displayFormat: 'HH:mm', pickerType: 'native' }} width={77} />
                                            <Column allowSorting={false} allowResizing={false} dataField="reserve" dataType="boolean" />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>

                    <div className="psad-buttons">
                        <div className="psad-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='psad-buttons-update'>
                            <div className="psad-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon={globalIcons.save}
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
                <HxPopupSaveChanges
                    onInitialized={this.popupSaveChanges_onInitialized}
                    onClick={this.popupSaveChanges_onClick}
                />

            </React.Fragment>
        );
    }

}

export default PopupDrivers

