import globalIcons from './config/globalIcons.js';

export const navigation = [
    {
        text: 'Home',
        path: '/home',
        icon: globalIcons.menu_home
    }
];


export const navigationSystem = {
    text: 'System',
    icon: globalIcons.menu_expand,
    expanded: true,
    items: [
        {
            text: 'Events',
            path: '/events',
            icon: globalIcons.menu_events
        },
        {
            text: 'Users',
            path: '/users',
            icon: globalIcons.menu_users
        }
    ]
};

export const navigationShuttles = {
    text: 'Shuttles',
    icon: globalIcons.menu_expand,
    expanded: true,
    items: [
        {
            text: 'Shuttles',
            path: '/shuttles',
            icon: globalIcons.menu_shuttles
        },
        {
            text: 'Admin',
            icon: globalIcons.menu_expand,
            expanded: true,
            items: [{
                text: 'Artists',
                path: '/shuttlesadminartists',
                icon: globalIcons.menu_artists
            },
            {
                text: '    Drivers',
                path: '/shuttlesadmindrivers',
                icon: globalIcons.menu_drivers
            },
            {
                text: 'Locations',
                path: '/shuttlesadminlocations',
                icon: globalIcons.menu_locations
            },
            {
                text: 'Fleet',
                path: '/shuttlesadminfleet',
                icon: globalIcons.menu_fleet
            }]
        },
        {
            text: 'Upcoming',
            path: '/shuttlesupcoming',
            icon: globalIcons.menu_stats
        }
    ]
};


export const navigationRiders = {
    text: 'Riders',
    icon: globalIcons.menu_expand,
    expanded: true,
    items: [
        {
            text: 'Riders',
            path: '/riders',
            icon: globalIcons.menu_shuttles
        },
        {
            text: 'Admin',
            icon: globalIcons.menu_expand,
            expanded: true,
            items: [{
                text: 'Artists',
                path: '/ridersadminartists',
                icon: globalIcons.menu_artists
            },
            {
                text: 'Products',
                path: '/ridersadminproducts',
                icon: globalIcons.menu_drivers
            },
            {
                text: 'Shops',
                path: '/ridersadminshops',
                icon: globalIcons.menu_locations
            }]
        }
    ]
};
export const navigationProfile = {
    text: 'Profile',
    icon: globalIcons.menu_expand,
    expanded: true,
    items: [
        {
            text: 'Profile',
            path: '/userprofile',
            icon: globalIcons.menu_profile
        },
        {
            text: 'My settings',
            path: '/usersettings',
            icon: globalIcons.menu_usersettings
        }
    ]
};