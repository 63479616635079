import axiosapi from '../axiosapi.js';
import ServiceJwt from './ServiceJwt.js';

class ServiceProducts {

    static getProductsInfo = async () => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Products/GetProductsInfo', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getProduct = async (id) => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Products/GetProduct', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static createProduct = async (model) => {
        let returnValue = -1;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.post('/api/Products/CreateProduct', JSON.stringify(model), {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });
        return returnValue;
    }

    static updateProduct = async (model) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.put('/api/Products/UpdateProduct', JSON.stringify(model), {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static deleteProduct = async (id) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.delete('/api/Products/DeleteProduct', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static canProductBeDeleted = async (id) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Products/CanProductBeDeleted', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesProductNameExist = async (productName, productIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await ServiceJwt.getBearerTokenUI();

        await axiosapi.get('/api/Products/DoesProductNameExist', {
            headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
            params: {
                productName: productName,
                productIdToExclude: productIdToExclude,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }
}

export default ServiceProducts;
