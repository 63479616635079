import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';

import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar} from 'devextreme-react/data-grid';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { ExcelJS, Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { Icon } from '@iconify/react';
import * as dayJS from 'dayjs';

import HxDialog from '../../custom-components/hx-dialog/HxDialog'
import HxPopupExportExcel from '../../custom-components/hx-popup-excel-export/HxPopupExportExcel'
import HxToast from '../../custom-components/hx-toast/HxToast';

import globalConfig from '../../../config/global.js';
import globalIcons from '../../../config/globalIcons.js';

import PopupProducts from './PopupProducts';

import ServiceRiders from '../../../api/services/ServiceRiders';
import ServiceEvents from '../../../api/services/ServiceEvents';
import ServiceRidersArtists from '../../../api/services/ServiceRidersArtists';

import './DataGridRiders.scss'

// callables
//
// callbacks
//      onInitialized
//      onDBError

class DataGridRiders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceRiders: [],
            showFilter: false,
            useAdvancedFilter: false,
        }

        this.dataSourceGigDates = [];
        this.dataSourceSubArtists = [];
        this.gridInstance = null;
        this.popupInstance = null;
        this.popupExportExcelInstance = null;

        this.ColsBand = [];
        this.ColsAdded = false;
        this.artistname = '';
        this.artistColorHex = '#FFFFFFFF';
        this.artistForeColorHex = '#000000FF';

        this.selectArtistId = -1;
        this.selectedGigDateIds = [];

        this.hubConnection = null;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    // CALLABLES
    reload = async (artistid, gigDateIds) => {
        this.ColsBand = [];
        this.ColsAdded = false;
        this.selectArtistId = artistid;
        this.selectedGigDateIds = gigDateIds;
        await this.loadDataSourceRiders(artistid, gigDateIds, true);
    }


    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceRiders(-1, -1, true);
        this.selectArtistId = -1;
        this.selectedGigDateIds = -1;
        await this.loadDataSourceGigDates();
        this.hubInit();
    }

    // HUB
    hubInit = async () => {
        this.hubConnection = new HubConnectionBuilder().withUrl(globalConfig.settings.apihost + "/ridersHub").build();
        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });
        this.hubConnection.on("ReceiveMessage", await this.hubReceiveMessage);
    }

    hubReceiveMessage = async () => {
      //  await this.loadDataSourceRiders(false);
    }

    hubSendUpdate = async () => {
        this.hubConnection
            .invoke("SendUpdate")
            .catch(async function (err) {
                await this.loadDataSourceProducts();
                return console.error(err.toString());
            });
    }

    // DATA
    loadDataSourceRiders = async (artistId, gigDateIds, showLoader) => {
        if (showLoader !== undefined && showLoader === true) {
            this.gridInstance.beginCustomLoading();
        }

        var data = await ServiceRiders.getRidersInfoByArtistIdGigDateIds(artistId, gigDateIds);
        if (!data.hasOwnProperty('error')) {
            if (data.length > 0) {
                this.artistName = data[0].artistName;
                this.artistColorHex = data[0].artistColorHex;
                this.artistForeColorHex = data[0].artistForeColorHex;
                await this.loadDataSourceSubArtists(data[0].artistId)
            }
            this.setState({ dataSourceRiders: data });
          } else {
            this.onDBError('Database error!');  // callback
        }

        if (showLoader !== undefined && showLoader === true) {
            this.gridInstance.endCustomLoading();
        }

        this.gridInstance.repaint();
        this.gridInstance.refresh();
    }

    loadDataSourceGigDates = async () => {
        var data = await ServiceEvents.getEventGigDatesDropDown(false);
        if (data !== null && !data.hasOwnProperty("error")) {
            let ds = [];
            for (var i = 0; i < data.length; i++) {
                let myDate = dayJS(data[i].gigDate).format('ddd DD/MM/YY');
                ds.push({ id: data[i].id, text: myDate })
            }
            this.dataSourceGigDates = data;
            //if (ds.length > 0) {
            //    await this.refGrid.reload(ds[0].id);
            //}
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    loadDataSourceSubArtists = async (artistId) => {
        var data = await ServiceRidersArtists.getSubArtistNamesByArtistId(artistId);
        if (data !== null && !data.hasOwnProperty("error")) {
            let ds = [];
            this.dataSourceSubArtists = data;
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    // GRID
    gridOnInitialized = (e) => {
        this.gridInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    gridOnCellPrepared = (e) => {
        if (e.rowType === 'data') {
            if (e.column.dataField === 'locationName' || e.column.dataField === 'productName') {
                e.cellElement.style.backgroundColor = e.data.productColorHex;
                e.cellElement.style.color = e.data.productForeColorHex;
            }
        } else if (e.rowType === 'header') {
            if ((e.column.dataField === undefined && e.column.caption !== '') || (e.column.dataField !== undefined && e.column.dataField.startsWith("_SUBARTIST_"))) {
                e.cellElement.style.backgroundColor = this.artistColorHex;
                e.cellElement.style.color = this.artistForeColorHex;
            }
        } 
    }

    gridOnToolbarPreparing = async (e) => {
        let toolbarItems = e.toolbarOptions.items;

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.excel,
                onClick: this.exportProduct
            },
            location: 'after'
        });

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.filter,
                onClick: this.switchFilter
            },
            location: 'after'
        });
        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: globalIcons.advanced_filter,
                onClick: this.switchAdvancedFilter
            },
            location: 'after'
        });
    }

    gridOnEditorPreparing = (e) => {
        if (e.parentType === 'dataRow' && e.editorName === "dxNumberBox") {
            e.editorOptions.step = 0;
            e.editorElement.parentElement.classList.add('hx-datagrid-cell-border');
        }
    }

    gridOnFocusedCellChanging = (e) => {
        let elements = document.getElementsByClassName("hx-datagrid-cell-border");
        if (elements) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].classList.remove('hx-datagrid-cell-border');
            }
        }

        e.cellElement[0].classList.add('hx-datagrid-cell-border');
    }

    gridOnRowUpdating = async (e) => {
        this.gridInstance.beginUpdate();
        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        for (var key in e.newData) {
            if (e.newData.hasOwnProperty(key)) {
                if (key.startsWith('_SUBARTIST_')) {
                    let colName = key.replace('_SUBARTIST_', '');
                    let gigDateId = parseInt(colName.split("_")[2], 10);
                    let subArtistId = parseInt(colName.split("_")[0], 10);
                    let productId = e.oldData.productId;
                    let amount = e.newData[key] ?? 0;
                    if (key.endsWith('_Amount')) {
                        if (subArtistId > -1) {
                            const modelAmount = {
                                EventId: userSettingsGeneral.currentEventId,
                                GigDateId: gigDateId,
                                SubArtistId: subArtistId,
                                ProductId: productId,
                                Amount: amount,
                                Comment: ''
                            }
                          var data = await ServiceRiders.updateRiderAmount(modelAmount);
                            if (data !== null && !data.hasOwnProperty("error") && data === true) {
                                //this.hubConnection
                                //    .invoke("SendUpdateInfoAmount", gigDateId, subArtistId, productId, amount)
                                //    .catch(function (err) {
                                //        return console.error(err.toString());
                                //    });

                            } else {
                                this.onConnectionError();  // callback
                            }
                        }
                    } else if (key.endsWith('_Comment')) {
                        let comment = e.newData[key];

                        if (subArtistId > -1) {
                            const modelComment = {
                                EventId: userSettingsGeneral.currentEventId,
                                GigDateId: gigDateId,
                                SubArtistId: subArtistId,
                                ProductId: productId,
                                Amount: 0,
                                Comment: comment.trim(),
                            }
                            var dataComment = await ServiceRiders.updateRiderComment(modelComment);
                            if (dataComment !== null && !data.hasOwnProperty("error") && dataComment === true) {
                                //this.hubConnection
                                //    .invoke("SendUpdateInfoComment", gigDateId, subArtistId, productId, comment)
                                //    .catch(function (err) {
                                //        return console.error(err.toString());
                                //    });

                            } else {
                                this.onConnectionError();  // callback
                            }
                        }
                    }
                }
                break;
            }
        }

        this.gridInstance.endUpdate();
    }

    gridOnEditingStart = (e) => {
        //if (!e.column || e.column.dataType === 'boolean') {
        //    return;
        //}

        //// make sure onrowupdating is always triggered after edit
        //var row = this.gridInstance.getRowIndexByKey(e.key);
        //var value = this.gridInstance.cellValue(row, e.column.dataField);

        //if (e.column.dataType.toLowerCase() === "string") {
        //    this.gridInstance.cellValue(row, e.column.dataField, (value !== "%___%" ? "%___%" : ""));
        //} else {
        //    this.gridInstance.cellValue(row, e.column.dataField, (value !== 999999 ? 999999 : 0));
        //}
        //this.gridInstance.cellValue(row, e.column.dataField, value);
    }

    gridOnEditCanceled = async (e) => {
        var id = this.gridInstance.getKeyByRowIndex(this.gridInstance.option('focusedRowIndex'));
        if (id) {
            await this.updateRow(id);
        }
    }

    gridOnCellHoverChanged = (e) => {
        if (e.rowType === 'header') {
            let color = '#000000';
            let band = true;
            for (var i = 0; i < e.cellElement.classList.length; i++) {
                if (e.cellElement.classList[i].startsWith('hx-column-band-style-')) {
                    const el = e.cellElement.classList[i];
                    const ind = el.split('-');
                    if (ind.length > 0) {
                        color = '#' + ind[ind.length - 1];
                    }
                    break;
                } else if (e.cellElement.classList[i].startsWith('hx-column-style-')) {
                    band = false;
                    const el = e.cellElement.classList[i];
                    const ind = el.split('-');
                    if (ind.length > 0) {
                        color = '#' + ind[ind.length - 1];
                    }

                    break;
                }
            }

            if (band) {
                e.cellElement.childNodes[0].classList.remove('dx-datagrid-text-content');
                if (e.eventType == 'mouseover') {
                    if (color.toUpperCase() == '#000000') {
                        e.cellElement.childNodes[0].classList.add('dx-datagrid-text-content-black');
                    } else {
                        e.cellElement.childNodes[0].classList.add('dx-datagrid-text-content-white');
                    }
                }

            } else {
                if (e.eventType == 'mouseover') {
                    e.cellElement.childNodes[1].classList.remove('dx-datagrid-text-content');
                    if (color.toUpperCase() == '#000000') {
                        e.cellElement.childNodes[1].classList.add('dx-datagrid-text-content-black');
                    } else {
                        e.cellElement.childNodes[1].classList.add('dx-datagrid-text-content-white');
                    }
                }
            }
        }
    }

    updateRow = async (id) => {
        //this.gridInstance.beginUpdate();
        //var data = await ServiceRiders.getRidersByEventIdGigDateIdsArtistIds(parseInt(sessionStorage.getItem("currentEventId"), 10), this.selectedGigDateIds, this.selectedArtistIds, this.userSettings.UseBlanksForZeroes, this.userSettings.ColorOriginProducts);
        //var dataRow = data.find(function (row) {
        //    return row.ProductId === id;
        //});
        //this.dataGridInstance.endUpdate();
        //if (dataRow) {
        //    var dsRow = this.state.dataSourceRiders.find(function (row) {
        //        return row.ProductId === id;
        //    });
        //    if (dsRow) {
        //        Object.entries(dsRow).forEach(([key, value]) => {
        //            dsRow[key] = dataRow[key];
        //        });
        //    }
        //}

        //var rowIndex = this.dataGridInstance.getRowIndexByKey(id);
        //this.dataGridInstance.repaintRows(rowIndex);
        //setTimeout(this.gridFocus, 10);
    }

    //gridFocus = () => {
    //    this.dataGridInstance.focus();
    //}

    deleteProduct = async (id) => {
        //var resultProduct = await ServiceProducts.deleteProduct(id);
        //if (!resultProduct.hasOwnProperty("error")) {
        //    if (resultProduct === true) {
        //        await this.hubSendUpdate();
        //        HxToast.showToast('Product deleted', 'success', 'top right', 'up-stack');
        //    } else {
        //        HxToast.showToast('Delete product failed!', 'error', 'top right', 'up-stack');
        //    }
        //} else {
        //    this.onDBError('Database error!'); // callback
        //    HxToast.showToast('Delete product failed!', 'error', 'top right', 'up-stack');
        //}
    }

    addSubArtistColumnsOrgWorks = () => {
        // define columns array (subartists)
        if (this.ColsAdded === false) {
            let productRow = {};
            if (this.state.dataSourceRiders !== undefined && this.state.dataSourceRiders.length > 0) {
                // get subartist column names and colors from first row
                productRow = this.state.dataSourceRiders[0];
                let artistId = -1;
                let artistName = '';
                let cols = [];
                let sheet = document.createElement('style');
                sheet.id = 'style-columns';
                let css = '';
                let cssBand = '';
                let prevFormattedGigDate = '';
                let prevGigDateId = -1;

                for (var key in productRow) {
                    if (productRow.hasOwnProperty(key)) {
                        if (key.startsWith('_SUBARTIST_') === true) {
                            let colName = key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '');
                            let colArtistId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[1], 10);
                            let gigDateId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[2], 10);
                            let gigDate = this.dataSourceGigDates.find(x => x.id === gigDateId);
                            let d = new Date(gigDate.gigDate);
                            let formattedGigDate = d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0');

                            prevGigDateId = gigDateId;

                            if (colArtistId !== artistId) {
                                if (artistId > -1) {
                                    //let gigDateId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[2], 10);
                                    //let gigDate = this.dataSourceGigDates.find(x => x.Id == gigDateId);
                                    //let d = new Date(gigDate.GigDate);
                                    //let formattedGigDate = d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0');
                                    this.ColsBand.push(<Column key={'band' + artistId + prevGigDateId} caption={artistName + ' ' + prevFormattedGigDate} cssClass={cssBand}>{cols}</Column>);  // key must be used in ES6 (unique)
                                }

                                cols = [];
                                artistId = colArtistId;
                                prevFormattedGigDate = formattedGigDate;
                            }

                            let saId = parseInt(colName.split("_")[0], 10);
                            let caption = colName;
                            let bg = '#FFFFFF';
                            let fg = '#000000';

                            //if (this.dataSourceSubArtistColors !== undefined && this.dataSourceSubArtistColors.length > 0) {
                            //    let index = this.dataSourceSubArtistColors.findIndex(subArtistId => subArtistId.SubArtistId === saId);
                            //    if (index > -1) {
                            //        artistName = this.dataSourceSubArtistColors[index].ArtistName;
                            //        caption = (this.dataSourceSubArtistColors[index].SubArtistTag ? this.dataSourceSubArtistColors[index].SubArtistTag + "-" : "") + this.dataSourceSubArtistColors[index].SubArtistName;
                            //        if (this.userSettings.UseColorsArtists) {
                            //            bg = this.dataSourceSubArtistColors[index].ColorHex != null ? this.dataSourceSubArtistColors[index].ColorHex : bg;
                            //            fg = this.dataSourceSubArtistColors[index].ForeColorHex != null ? this.dataSourceSubArtistColors[index].ForeColorHex : fg;
                            //        }
                            //    }
                            //}

                            // e.cellElement.classList[0]
                            css = 'hx-column-style-' + saId + '-' + fg.slice(1);
                            if (sheet.innerHTML.indexOf(css) == -1) {
                                sheet.innerHTML += '.' + css + ' {color: ' + fg + ' !important; background-color: ' + bg + ' !important; min-width:50px !important;}';
                                sheet.innerHTML += '.' + css + ':hover {color: ' + fg + ' !important; background-color: ' + bg + ' !important; min-width:50px; !important}';
                                cssBand = 'hx-column-band-style-' + saId + '-' + fg.slice(1);
                                sheet.innerHTML += '.' + cssBand + ' {color: ' + fg + ' !important; background-color: ' + bg + ' !important;}';
                                sheet.innerHTML += '.' + cssBand + ':hover {color: ' + fg + ' !important; background-color: ' + bg + ' !important;}';
                                //      sheet.innerHTML += '.dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover .dx-datagrid-text-content { background-color: white; color: black; }'
                            }

                            if (key.endsWith('_Amount')) {
                                cols.push(<Column key={'col' + colName + 'amount'} dataField={key} dataType='number' caption={caption} visible={true} cssClass={css} allowEditing={true} allowResizing={false} allowFiltering={false} width='auto' minWidth='50px' />);  // key must be used in ES6 (unique)
                            } else if (key.endsWith('_Comment')) {
                                caption += ' Comment'
                                cols.push(<Column key={'col' + colName + 'comment'} dataField={key} dataType='string' caption={caption} visible={true} cssClass={css} allowEditing={true} allowResizing={false} allowFiltering={false} width='auto' minWidth='50px' />);  // key must be used in ES6 (unique)
                            }
                        }
                    }
                }

                if (artistId > -1) {
                    this.ColsBand.push(<Column key={'band' + artistId + prevGigDateId} caption={artistName + ' ' + prevFormattedGigDate} cssClass={cssBand}>{cols}</Column>);  // key must be used in ES6 (unique)
                }

                //this.ColsBand.push(<Column key='coldummy' caption='' allowFiltering={false} width='100%' visible={true} />);

                this.ColsAdded = true;

                this.styleSheet = sheet;

                //var child = document.getElementById('style-columns');
                //if (child) {
                //    document.body.removeChild(child);
                //}
                //document.body.appendChild(sheet);

                // if column is removed and filtered => remove filter, otherwise crash
                if (this.dataGridInstance) {
                    let arrFilter = this.dataGridInstance.option('columns').filter(function (col) { return col.hasOwnProperty('columns') })  // if 'columns' is defined we are dealing with artist
                    for (let col of arrFilter) {
                        for (let sa of col.columns) {
                            if (!productRow.hasOwnProperty(sa.dataField)) {
                                this.dataGridInstance.columnOption(sa.dataField, 'filterValue', null);
                            }
                        }
                    }
                }
            }
            //else {
            //    // if column is removed and filtered => remove filter, otherwise crash
            //    if (this.dataGridInstance) {
            //        let arrFilter = this.dataGridInstance.option('columns').filter(function (col) { return col.hasOwnProperty('columns') })  // if 'columns' is defined we are dealing with artist
            //        for (let col of arrFilter) {
            //            for (let sa of col.columns) {
            //                this.dataGridInstance.columnOption(sa.dataField, 'filterValue', null);
            //            }
            //        }
            //    }
            //}
        }
    }

    addSubArtistColumnsOK = () => {
        // define columns array (subartists)
        if (this.ColsAdded === false) {
            let productRow = {};
            if (this.state.dataSourceRiders !== undefined && this.state.dataSourceRiders.length > 0) {
                // get subartist column names and colors from first row
                productRow = this.state.dataSourceRiders[0];
                let artistId = -1;
                let artistName = productRow["artistName"];
                let cols = [];
                let prevFormattedGigDate = '';
                let prevGigDateId = -1;

                for (var key in productRow) {
                    if (productRow.hasOwnProperty(key)) {
                        if (key.startsWith('_SUBARTIST_') === true) {
                            let colName = key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '');
                            let colArtistId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[1], 10);
                            let gigDateId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[2], 10);
                            let gigDate = this.dataSourceGigDates.find(x => x.id === gigDateId);
                            let d = new Date(gigDate.gigDate);
                            let formattedGigDate = d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0');

                            prevGigDateId = gigDateId;

                            if (colArtistId !== artistId) {
                                if (artistId > -1) {
                                    this.ColsBand.push(<Column key={'band' + artistId + prevGigDateId} caption={artistName + ' ' + prevFormattedGigDate}>{cols}</Column>);  // key must be used in ES6 (unique)
                                }

                                cols = [];
                                artistId = colArtistId;
                                prevFormattedGigDate = formattedGigDate;
                            }

                            let caption = colName;

                            if (key.endsWith('_Amount')) {
                                this.ColsBand.push(<Column key={'col' + colName + 'amount'}
                                    dataField={key}
                                    dataType='number'
                                    caption={caption}
                                    visible={true}
                                    allowEditing={true}
                                    allowResizing={false}
                                    allowFiltering={false}
                                    width='auto'
                                    minWidth='50px'
                                />);  // key must be used in ES6 (unique)
                            } else if (key.endsWith('_Comment')) {
                                    caption += ' Comment'
                                this.ColsBand.push(<Column
                                        key={'col' + colName + 'comment'}
                                        dataField={key}
                                        dataType='string'
                                        caption={caption}
                                        visible={true}
                                        allowEditing={true}
                                        allowResizing={false}
                                        allowFiltering={false}
                                        width='auto'
                                        minWidth='50px'
                                />);  // key must be used in ES6 (unique)
                            }
                        }
                    }
                }

                if (artistId > -1) {
                    //this.ColsBand.push(<Column key={'band' + subArtistId + prevGigDateId} caption={artistName + ' ' + prevFormattedGigDate}>{cols}</Column>);  // key must be used in ES6 (unique)
                    //this.ColsBand = {cols};  // key must be used in ES6 (unique)
                }

                //this.ColsBand.push(<Column key='coldummy' caption='' allowFiltering={false} width='100%' visible={true} />);

                this.ColsAdded = true;

                //var child = document.getElementById('style-columns');
                //if (child) {
                //    document.body.removeChild(child);
                //}
                //document.body.appendChild(sheet);

                // if column is removed and filtered => remove filter, otherwise crash
                if (this.gridInstance) {
                    let arrFilter = this.gridInstance.option('columns').filter(function (col) { return col.hasOwnProperty('columns') })  // if 'columns' is defined we are dealing with artist
                    for (let col of arrFilter) {
                        for (let sa of col.columns) {
                            if (!productRow.hasOwnProperty(sa.dataField)) {
                                this.gridInstance.columnOption(sa.dataField, 'filterValue', null);
                            }
                        }
                    }
                }
            }
            //else {
            //    // if column is removed and filtered => remove filter, otherwise crash
            //    if (this.dataGridInstance) {
            //        let arrFilter = this.dataGridInstance.option('columns').filter(function (col) { return col.hasOwnProperty('columns') })  // if 'columns' is defined we are dealing with artist
            //        for (let col of arrFilter) {
            //            for (let sa of col.columns) {
            //                this.dataGridInstance.columnOption(sa.dataField, 'filterValue', null);
            //            }
            //        }
            //    }
            //}
        }
    }

    addSubArtistColumns = async () => {
        // define columns array (subartists)
        if (this.ColsAdded === false) {
            let productRow = {};
            if (this.state.dataSourceRiders !== undefined && this.state.dataSourceRiders.length > 0) {
                // get subartist column names and colors from first row
                productRow = this.state.dataSourceRiders[0];
                let subArtistId = -1;
                let cols = [];
                let prevFormattedGigDate = '';
                let prevGigDateId = -1;

                for (var key in productRow) {
                    if (productRow.hasOwnProperty(key)) {
                        if (key.startsWith('_SUBARTIST_') === true) {
                            let colName = key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '');
                            //let colArtistId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[1], 10);
                            let colSubArtistId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[0], 10);
                            let gigDateId = parseInt(key.replace('_SUBARTIST_', '').replace('_Amount', '').replace('_Comment', '').split("_")[2], 10);
                            let gigDate = this.dataSourceGigDates.find(x => x.id === gigDateId);
                            let d = new Date(gigDate.gigDate);
                            let formattedGigDate = d.getDate().toString().padStart(2, '0') + '/' + (d.getMonth() + 1).toString().padStart(2, '0');

                            prevGigDateId = gigDateId;

                            if (colSubArtistId !== subArtistId) {
                                let subArtistName = '';
                                let subArtistTag = '';
                                if (subArtistId > -1) {
                                    if (this.dataSourceSubArtists.length > 0) {
                                        let rec = this.dataSourceSubArtists.find(x => x.id === subArtistId);
                                        if ((rec !== null && rec !== undefined)) {
                                            subArtistName = rec.name;
                                            if (subArtistName === null || subArtistName === undefined) {
                                                subArtistName = '';
                                            }
                                            subArtistTag = rec.tag;
                                            if (subArtistTag === null || subArtistTag === undefined) {
                                                subArtistTag = '';
                                            }                                 }
                                 
                                    }
                                    this.ColsBand.push(<Column key={'sub' + subArtistId + prevGigDateId} caption={subArtistName + ' ' + subArtistTag + ' ' + prevFormattedGigDate} minWidth='600px'>{cols}</Column>);  // key must be used in ES6 (unique)
                                }

                                cols = [];
                                subArtistId = colSubArtistId;
                                prevFormattedGigDate = formattedGigDate;
                            }

                            if (key.endsWith('_Amount')) {
                                cols.push(<Column key={'col' + colName + 'amount'}
                                    dataField={key}
                                    dataType='number'
                                    caption='Amount'
                                    visible={true}
                                    allowEditing={true}
                                    allowResizing={false}
                                    allowFiltering={false}
                                    width='100px'
                                    minWidth='50px'
                                />);  // key must be used in ES6 (unique)
                            } else if (key.endsWith('_Comment')) {
                                cols.push(<Column
                                    key={'col' + colName + 'comment'}
                                    dataField={key}
                                    dataType='string'
                                    caption='Comment'
                                    visible={true}
                                    allowEditing={true}
                                    allowResizing={false}
                                    allowFiltering={false}
                                    width='150px'
                                    minWidth='50px'
                                />);  // key must be used in ES6 (unique)
                            }
                        }
                    }
                }

                if (subArtistId > -1) {
                    let subArtistName = '';
                    let subArtistTag = '';
                    if (subArtistId > -1) {
                        if (this.dataSourceSubArtists.length > 0) {
                            let rec = this.dataSourceSubArtists.find(x => x.id === subArtistId);
                            if ((rec !== null && rec !== undefined)) {
                                subArtistName = rec.name;
                                if (subArtistName === null || subArtistName === undefined) {
                                    subArtistName = '';
                                }
                                subArtistTag = rec.tag;
                                if (subArtistTag === null || subArtistTag === undefined) {
                                    subArtistTag = '';
                                }
                            }

                        }
                        this.ColsBand.push(<Column key={'sub' + subArtistId + prevGigDateId} caption={subArtistName + ' ' + subArtistTag + ' ' + prevFormattedGigDate}>{cols}</Column>);  // key must be used in ES6 (unique)
                    }
                }

                //this.ColsBand.push(<Column key='coldummy' caption='' allowFiltering={false} width='100%' visible={true} />);

                this.ColsAdded = true;

                //var child = document.getElementById('style-columns');
                //if (child) {
                //    document.body.removeChild(child);
                //}
                //document.body.appendChild(sheet);

                // if column is removed and filtered => remove filter, otherwise crash
                if (this.gridInstance) {
                    let arrFilter = this.gridInstance.option('columns').filter(function (col) { return col.hasOwnProperty('columns') })  // if 'columns' is defined we are dealing with artist
                    for (let col of arrFilter) {
                        for (let sa of col.columns) {
                            if (!productRow.hasOwnProperty(sa.dataField)) {
                                this.gridInstance.columnOption(sa.dataField, 'filterValue', null);
                            }
                        }
                    }
                }
            }
            //else {
            //    // if column is removed and filtered => remove filter, otherwise crash
            //    if (this.dataGridInstance) {
            //        let arrFilter = this.dataGridInstance.option('columns').filter(function (col) { return col.hasOwnProperty('columns') })  // if 'columns' is defined we are dealing with artist
            //        for (let col of arrFilter) {
            //            for (let sa of col.columns) {
            //                this.dataGridInstance.columnOption(sa.dataField, 'filterValue', null);
            //            }
            //        }
            //    }
            //}
        }
    }




   // POPUP CREATE/UPDATE
    popup_onInitialized = (instance) => {
        this.popupInstance = instance;
    }

    popup_onDBError = (message) => {
        this.onDBError(message)
    }

    popup_OnCreate = async (id) => {
        if (id !== -1) {
            await this.hubSendUpdate();
            HxToast.showToast('Product created', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Create product failed!', 'error', 'top right', 'up-stack');
        }
    }

    popup_OnUpdate = async (success) => {
        if (success === true) {
            await this.hubSendUpdate();
            HxToast.showToast('Product updated', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Update product failed!', 'error', 'top right', 'up-stack');
        }
    }

    // POPUP EXPORT
    popupExportExcel_OnInitialized = (instance) => {
        this.popupExportExcelInstance = instance;
    }

    popupExportExcel_OnSave = async (fileName) => {
        if (fileName) {
            await this.exportToExcel(fileName);
        }
    }

    exportProduct = () => {
        this.popupExportExcelInstance.show();
    }

    exportToExcel = (name) => {
        const fileName = name;
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Product', {
            pageSetup: { paperSize: 9, orientation: 'landscape' }
        });

        worksheet.pageSetup.margins = {
            left: 0.3, right: 0.3,
            top: 0.3, bottom: 0.3,
            header: 0.3, footer: 0.3
        };

        exportDataGrid({
            component: this.gridInstance,
            worksheet: worksheet,
            customizeCell: this.customizeExcelCell,
            keepColumnWidths: true,
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                });
        });
    }

    // set excel cell layout
    customizeExcelCell = (options) => {
        const { gridCell, excelCell } = options;

        if (gridCell.rowType === 'header') {
            excelCell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
        }
    }


    switchFilter = () => {
        this.setState(prevState => ({
            showFilter: !prevState.showFilter
        }));
    }

    switchAdvancedFilter = () => {
        this.setState(prevState => ({
            useAdvancedFilter: !prevState.useAdvancedFilter
        }));
        this.gridInstance.repaint();
    }

    // RENDERING
    renderCreateTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start', marginLeft: '-14px', position: 'absolute', cursor: 'pointer' }}>
            <div style={{ width: '30px' }}><Icon icon="mdi:plus-thick" style={{ color: '#03a9f4', fontSize: '16px' }} /></div>
        </div>;
    }

    renderUpdateTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start', marginLeft: '-3px', cursor: 'pointer' }}>
            <div style={{ width: '30px' }}><Icon icon="mdi:pencil" style={{ color: '#03a9f4' }} /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        if (e.data.canBeDeleted === true) {
            return <div style={{ display: 'flex', justifyContent: 'start', cursor: 'pointer' }}>
                <div style={{ width: '30px', marginLeft: '-3px' }}><Icon icon="mdi:delete" style={{ color: '#03a9f4' }} /></div>
            </div>;
        }
        return <div style={{ display: 'flex', justifyContent: 'start', cursor: 'pointer' }}>
            <div style={{ width: '30px', marginLeft: '-3px' }}><Icon icon="mdi:delete" style={{ color: '#999999' }} /></div>
        </div>;
    }

    endRenderUpdate = () => {
        //try {
        //    var child = document.getElementById('style-columns');
        //    if (child) {
        //        document.body.removeChild(child);
        //    }
        //    document.body.appendChild(this.styleSheet);
        //}
        //catch (err) {
        //}
        //this.dataGridInstance.endUpdate();
    }
    render() {
        // get subartist cols
        this.addSubArtistColumns();

        //if (this.dataGridInstance) {
        //    setTimeout(this.endRenderUpdate, 300);
        //}

        return (
            <React.Fragment>
                <div className="dgr-main-div">
                    <div className='dx-card dgr-grid'>
                        <DataGrid
                            ref={ref => this.refGrid = ref}
                            dataSource={this.state.dataSourceRiders}
                            keyExpr='id'
                            height='inherit'
                            allowColumnReordering={false}
                            allowColumnResizing={false}
                            cacheEnabled={true}
                            columnAutoWidth={false}
                            columnResizingMode='widget'
                            filterSyncEnabled={true}
                            focusedRowEnabled={false}
                            hoverStateEnabled={false}
                            noDataText='No riders found.'
                            remoteOperations={true}
                            repaintChangesOnly={true}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            wordWrapEnabled={false}
                            onInitialized={this.gridOnInitialized}
                            onCellPrepared={this.gridOnCellPrepared}
                            onToolbarPreparing={this.gridOnToolbarPreparing}
                            onEditorPreparing={this.gridOnEditorPreparing}
                            onFocusedCellChanging={this.gridOnFocusedCellChanging}
                            onRowUpdating={this.gridOnRowUpdating}
                            onCellHoverChanged={this.gridOnCellHoverChanged}
                            onEditCanceled={this.gridOnEditCanceled}
                            onEditingStart={this.gridOnEditingStart}
                        >
                            <StateStoring enabled={false} type="localStorage" storageKey="storageRidersDataGrid" />
                            <KeyboardNavigation
                                enabled={true}
                                editOnKeyPress={true}
                                enterKeyAction='moveFocus'
                                enterKeyDirection='column' />
                            <Editing
                                mode="cell"
                                allowUpdating={true}
                                selectTextOnEditStart={true}
                                startEditAction='dblClick'
                                step={0}
                            />
                            <Sorting mode='multiple' />
                            <ColumnChooser enabled={false} />
                            <Paging enabled={false} defaultPageSize={20} />
                            <FilterPanel visible={this.state.useAdvancedFilter} />
                            <FilterRow visible={this.state.showFilter} />
                            <GroupPanel visible={false} />
                            <HeaderFilter visible={true} />
                            <SearchPanel visible={true} />
                            <Scrolling
                                mode="standard"
                                columnRenderingMode="standard"
                                rowRenderingMode="standard"
                                showScrollBar='onHover'
                                preloadEnabled={true}
                            />
                            <Column
                                dataField='id'
                                dataType='number'
                                caption='Id'
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='productId'
                                dataType='number'
                                caption='ColorId'
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='productColorHex'
                                dataType='string'
                                caption='ColorHex'
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='productForeColorHex'
                                dataType='string'
                                caption='ForeColorHex'
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='productName'
                                dataType='string'
                                caption='Product'
                                width='400px'
                                allowEditing={false}
                                allowExporting={true}
                                allowFiltering={true}
                                allowHeaderFiltering={true}
                                allowSorting={true}
                                cssClass='wrapped-column-class'
                                showInColumnChooser={true}
                            />
                            <Column
                                dataField='locationName'
                                dataType='string'
                                caption='Shop'
                                width='250px'
                                allowEditing={false}
                                allowExporting={true}
                                allowFiltering={true}
                                allowHeaderFiltering={true}
                                cssClass='wrapped-column-class'
                                showInColumnChooser={true}
                            />

                            {this.ColsBand}

                            <Column key='productcoldummy' name='dummycol' caption='' allowFiltering={false} allowResizing={true} allowExporting={false} width='100%' />
                        </DataGrid>
                    </div>
                </div>
                <PopupProducts
                    ref={ref => this.refPopup = ref}
                    onInitialized={this.popup_onInitialized}
                    onDBError={this.popup_onDBError}
                    onCreate={this.popup_OnCreate}
                    onUpdate={this.popup_OnUpdate}
                />
                <HxPopupExportExcel
                    defaultName='Product'
                    onInitialized={this.popupExportExcel_OnInitialized}
                    onSave={this.popupExportExcel_OnSave}
                />
            </React.Fragment>
        );

    }
}

export default DataGridRiders

