import React from 'react';
import Tabs from 'devextreme-react/tabs';
import * as dayJS from 'dayjs';

import DataGridRiders from './sub-components/DataGridRiders';
import HxToast from '../custom-components/hx-toast/HxToast';
import HxTags from '../custom-components/hx-tags/HxTags'

import ServiceEvents from '../../api/services/ServiceEvents'
import ServiceRidersArtists from '../../api/services/ServiceRidersArtists';

import globalConfig from '../../config/global.js';

import './Riders.scss';

class Riders extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceGigDates: [],
            artistTags: []
        }

        this.tabsInstance = null;

        this.selectedGigDateIds = [];
        this.selectedArtistTags = [];
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceGigDates();
    }

    // DATA
    loadDataSourceGigDates = async () => {
        var data = await ServiceEvents.getEventGigDatesDropDown(false);
        if (data !== null && !data.hasOwnProperty("error")) {
            let ds = [];
            for (var i = 0; i < data.length; i++) {
                let myDate = dayJS(data[i].gigDate).format('ddd DD/MM/YY');
                ds.push({ id: data[i].id, text: myDate })
            }
            this.setState({ dataSourceGigDates: ds });
            if (ds.length > 0) {
                this.tabsInstance.option('selectedIndex', 0);
                this.selectedGigDateIds = [ds[0].id];
                await this.loadDataSourceArtists();
                //await this.refGrid.reload(ds[0].id);
            }
        } else {
            this.gridRiders_OnDBError('Database error!'); 
        }
    }

    loadDataSourceArtists = async () => {
        var data = await ServiceRidersArtists.getArtistNamesByGigDateIds(this.selectedGigDateIds);
        if (!data.hasOwnProperty('error')) {
            let ds = [];
            for (var i = 0; i < data.length; i++) {
                ds.push({ id: data[i].id, text: data[i].name, selected: false });
            };
            this.setState({ artistTags: ds });
            if (ds.length > 0) {
                this.selectedArtistTags = [ds[0].id];
            }
            this.refTags.setTags(ds)
        } else {
            this.gridRiders_OnDBError('Database error!');
        }
    }

    // GRID
    gridRiders_OnDBError = (message) => {
        HxToast.showToast(message, 'error', 'top center', 'up-stack');
    }

    // TABS
    tabsOnInitialized = (e) => {
        this.tabsInstance = e.component;
    }

    tabsOnItemClick = async (e) => {
        //await this.refGrid.reload(e.itemData.id);
        //this.tabsInstance.option('selectedIndex', e.itemIndex);
        let selectedItems = this.tabsInstance.option('selectedItems');
        const index = selectedItems.findIndex(x => x.id === e.itemData.id);
        if (!e.event.ctrlKey) {
            this.tabsInstance.option('selectedItems', [selectedItems[index]]);
        }
        this.selectedGigDateIds = this.tabsInstance.option('selectedItems').map(x => x.id);
        await this.loadDataSourceArtists();
    }

    // TAGS
    tagArtists_OnClick = async (e, selected) => {
        let ds = this.state.artistTags;
        const index = ds.findIndex(x => x.id === parseInt(e.currentTarget.id, 10));
        for (var i = 0; i < ds.length; i++) {
            ds[i].selected = false;
        };
        ds[index].selected = true;
        this.setState({ artistTags: ds });

        const id = parseInt(e.currentTarget.id, 10);
        this.refGrid.reload(id, this.selectedGigDateIds.join(','));
    }
 
    // FUNCTIONS


    render() {
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));
        if (userAuth.isSuperAdmin === false && userAuth.isRidersUser === false && userAuth.isRidersAdmin === false) {
            window.location.href = '/home';
            return "Not Authorized";
        }

        return (
            <React.Fragment>
                <div className='content-block'>
                    <div Fragment="container-grid">
                        <div>
                            <div className='riders-grid-title'>
                                <div className='riders-grid-title-text'>Riders</div>
                                <div className='riders-grid-title-options'>
                                    <span className='riders-grid-span-dates'>

                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Tabs
                                dataSource={this.state.dataSourceGigDates}
                                selectionMode='multiple'
                                onItemClick={this.tabsOnItemClick}
                                onInitialized={this.tabsOnInitialized}
                            />
                        </div>
                        <div class="tags-div">
                            <HxTags
                                ref={ref => this.refTags = ref}
                                tags={this.state.artistTags}
                                onClick={this.tagArtists_OnClick}
                            />
                        </div>
                        <div class="grid-div">
                            <DataGridRiders
                                ref={ref => this.refGrid = ref}
                                onDBError={this.gridShuttles_OnDBError}
                                onDataLoaded={this.gridShuttles_OnDataLoaded}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Riders
